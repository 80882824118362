import * as React from 'react';

import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import { Button } from '@mui/material';

import style from './StartedMeeting.module.css';
import './StartedMeeting.css';

import parseNumber from '../../../functions/parseNumber';
import { DURATION_JUMP_IN_MS } from '../../../constants';
import { ReactComponent as IconSettings } from '../../../images/Icon_settings.svg';

//popups
import GatherPopup from '../../../components/Meeting/Voting/Host/GatherPopup';
import EndQckWarning from '../../../components/Meeting/EndQckWarning';
import GivePopup from '../../../components/Meeting/Voting/Host/GivePopup';
import ResultsPopup from '../../../components/Meeting/Voting/Results/ResultsPopup';

import FullScreenLoadingOverlay from '../../../components/FullScreenLoadingOverlay';

import { ToggleButton } from 'primereact/togglebutton';

//unsure what is this
import { useHash } from '../../../hooks/useHash';
import Editor from '../../../components/Meeting/Started/Editor';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import sanitizeInvitees from '../../../functions/SanitizeInvitees';
import useChangeBodyScrollStateOnShow from '../../../hooks/useChangeBodyScrollStateOnTrue';

import CustomMarkdownRenderer from '../../../components/CustomMarkdownRenderer';
import gfm from 'remark-gfm';

export default function StartedMeeting(props: {
  meeting: Meeting
}) {

  //keeping current meeting data live
  const isRequestActive = React.useRef<boolean>();
  const requestId = React.useRef<number>();

  //current meeting data
  const [ currentWorkingQuestionIndex, setCurrentWorkingQuestionIndex ] = React.useState<number>(props.meeting.currentWorkingQuestionIndex);
  const [ workingQuestions, setWorkingQuestions ] = React.useState<WorkingQuestion[]>(props.meeting.workingQuestions);
  const [ minutes, setMinutes ] = React.useState<Minute[]>(props.meeting.workingQuestionMinutes);
  const [ invitees, setInvitees ] = React.useState<Invitee[]>(props.meeting.invitees);
  const [ feedback, setFeedback ] = React.useState<Feedback>(props.meeting.feedback);
  
  const [ minutesContributeAllowed, setMinutesContributeAllowed ] = React.useState<boolean>(false);

  const { dateTimeEnded, type, ownerEmail } = props.meeting;
  const { id } = useParams();

  //Timeout id

  const [currentWorkingQuestionDuration, setCurrentWorkingQuestionDuration] = React.useState<Seconds>(0);
  const [currentWorkingQuestionDurationDisplay, setCurrentWorkingQuestionDurationDisplay] = React.useState('--:--');

  //Popups
  const [shouldShowWarningPopup, setShouldShowWarningPopup] = React.useState<boolean>(false);

  const [shouldShowVotePopup, setShouldShowVotePopup] = React.useState<boolean>(false);
  const [shouldShowResultsPopup, setShouldShowResultsPopup] = React.useState<boolean>(false);
  const [shouldShowGatherPopup, setShouldShowGatherPopup] = React.useState<boolean>(false);

  //Loading
  const [ loading, setLoading ] = React.useState<boolean>(false);

  //Notes for working question will be previewed if its index is present in the array
  const [ shouldShowNotes, setShouldShowNotes ] = React.useState<number[]>([]);

  const toggleShowNotes = (index: number) => {
    setShouldShowNotes(curr => {
      const wqIndexLocation = curr.indexOf(index);
      console.log(wqIndexLocation, curr);
      if(wqIndexLocation !== -1){
        curr.splice(wqIndexLocation, 1);
        return curr;
      }
      return [ ...curr, index ];
    })
  }

  const hash = useHash();

  const navigate = useNavigate();

  const updateMinutesContributeAllowed = (newState: boolean) => {
    setMinutesContributeAllowed(newState);
    //TODO make this shit be called every sec
    setMinuteChanges((curr) => ({ ...curr, newContributionAllow: newState }));
  }

  const updateCurrentWorkingQuestionDuration = (newDuration?: number, updateState?: boolean) => {
    const curr = workingQuestions;
    const _new = curr.map((wq, index) => {
      if(index === currentWorkingQuestionIndex){
        const duration = newDuration === undefined ? (wq.duration || 0) + (DURATION_JUMP_IN_MS / 1000) : newDuration;
        return { ...wq, duration };
      }
      return wq;

    })

    axios.post('/api/meeting-agenda', {id, changes: [ { type: 'duration', props: { index: currentWorkingQuestionIndex, newDuration: _new[currentWorkingQuestionIndex].duration }} ]});
    if(updateState){
      setWorkingQuestions(_new);
    }
  }

  const changeCurrentWorkingQuestionIndex = (to: number) => {
    axios
    .put('/api/meeting-change-wq-index', { currentWorkingQuestionIndex: to, id })
    setCurrentWorkingQuestionIndex(to);
    setCurrentWorkingQuestionDuration(workingQuestions[to].duration || 0);
    setCurrentWorkingQuestionDurationDisplay(secondsToMMSS(workingQuestions[to].duration || 0));
    setWorkingQuestions((curr) => curr.map((wq, index) => {
      if(index === to && wq.startDateTime === undefined){
        return { ...wq, startDateTime: Date.now() };
      }
      return wq;
    }))
  }

  const secondsToMMSS = (s: number): string => {
    const minutes = Math.floor(s / 60);
    const seconds = s - (minutes * 60);
    return `${parseNumber(minutes)}:${parseNumber(seconds)}`
  }
  
  const [ minuteChanges, setMinuteChanges ] = React.useState<MinuteChanges>({ newMinutes: [] });

  const isMinuteSyncActive = React.useRef<boolean>();

  const syncMinutes = () => {
    setMinuteChanges(curr => {

      if(isMinuteSyncActive.current) return curr;

      axios
      .post('/api/meeting-minute', { id, changes: curr })
      .then((response) => {
        const _meeting = response.data as Meeting;
        setMinuteChanges(curr_after => {
          if(!curr_after.newMinutes || curr_after.newMinutes.length <= 0){
            setMinutes(_meeting.workingQuestionMinutes)
          }
          if(curr_after.newContributionAllow === undefined){
            setMinutesContributeAllowed(_meeting.isMinuteContributionAllowed || false);
          }
          return curr_after;
        })
        isMinuteSyncActive.current = false;
      })

      isMinuteSyncActive.current = true;
      return { newMinutes: [] }
    })
  }

  const addMinuteChange = (index: number, text: string) => {
    setMinuteChanges(curr => {
      const _new = [ ...curr.newMinutes ];
      _new[index] = text;

      return { ...curr, newMinutes: _new };
    });
  }

  const handleMinuteWrite = (markdown: string) => {
    const newText = markdown;
    console.log(markdown);

    setMinutes((curr) => {
      const newMinutes = curr.map((min, index) => {
        if(index === currentWorkingQuestionIndex){
          return { ...min, text: newText };
        }
        return min;
      })

      addMinuteChange(currentWorkingQuestionIndex, markdown);
      console.log(newMinutes[0]);
      return newMinutes;
    })
  }

  const submitVote = (answers: Answer[], opinions: Opinion[]) => {
     setLoading(true);
     axios
     .patch('/api/meeting-feedback', { answers, opinions: opinions, id })
     .then(() =>{
       setLoading(false);
       window.location.reload()
     });
  }
  const endQck = () => {
    setLoading(true);
    axios
    .put('/api/meeting-end', { id })
    .then(() => {
      setLoading(false);
      window.location.href = `/${id}/evaluation`;
    })
  }

  const handleEndQckClick = () => {
    
    let allFeedbackGathered = Object.keys(feedback).length > 0;
    for(let i = 0; i < Object.keys(feedback).length; i++){
      if(!feedback[i]){
        allFeedbackGathered = false;
        break;
      }
      if(feedback[i].votingEndedDateTime === undefined || feedback[i].votingStartedDateTime === undefined){
        allFeedbackGathered = false;
        break;
      }
    }
    
    if(allFeedbackGathered){
      endQck();
    }else{
      setShouldShowWarningPopup(true);
    }
    
  }

  const handleStartVotingClick = () => {
    setShouldShowGatherPopup(true);
    if(feedback[currentWorkingQuestionIndex] && feedback[currentWorkingQuestionIndex].votingStartedDateTime){
      return;
    }
    setFeedback(curr => {
      const newFeedback = { ...curr };
      newFeedback[currentWorkingQuestionIndex] = { opinions: {}, answers: {}, votingStartedDateTime: Date.now() }
      return newFeedback;
    })
    axios
    .put('/api/meeting-start-voting', { id, index: currentWorkingQuestionIndex })
  }
  const handleShowResultsClick = () => {
    setShouldShowResultsPopup(true);
    setShouldShowGatherPopup(false);
    setShouldShowVotePopup(false);
  }

  //useChangeBodyScrollStateOnShow(shouldShowVotePopup);
  //useChangeBodyScrollStateOnShow(shouldShowGatherPopup);
  //useChangeBodyScrollStateOnShow(shouldShowResultsPopup);

  const endVoting = () => {
    setShouldShowGatherPopup(false);
    setFeedback((curr) => {
      const newFeedback = { ...curr };
      newFeedback[currentWorkingQuestionIndex].votingEndedDateTime = Date.now();
      axios
      .put('/api/meeting-end-voting', { id, feedback: newFeedback })
      return curr;
    })
    return;
  }

  React.useEffect(() => {
    const currFeedback = feedback[currentWorkingQuestionIndex];
    if(currFeedback && currFeedback.votingStartedDateTime && !currFeedback.votingEndedDateTime && !shouldShowVotePopup && !shouldShowResultsPopup){
      setShouldShowGatherPopup(true);
    }
  }, [feedback]);

  React.useEffect(() => {
    if(shouldShowVotePopup || shouldShowResultsPopup || shouldShowGatherPopup){
      document.body.classList.add('no-scroll');
    }else{
      document.body.classList.remove('no-scroll');
    }

  }, [shouldShowVotePopup, shouldShowResultsPopup, shouldShowGatherPopup]);

  React.useEffect(() => {

    //Duration
    const updateClientsideDurationInterval = window.setInterval(() => {
      setCurrentWorkingQuestionDuration((curr) => {
        setCurrentWorkingQuestionDurationDisplay(secondsToMMSS(curr + 1));
        return curr + 1;
      });
    }, 1000)

    const syncDurationInterval = window.setInterval(() => {
      updateCurrentWorkingQuestionDuration(undefined);
    }, DURATION_JUMP_IN_MS)

    setCurrentWorkingQuestionDuration(() => {
      const duration = workingQuestions[currentWorkingQuestionIndex].duration || 0;
      setCurrentWorkingQuestionDurationDisplay(secondsToMMSS(duration));
      return duration;
    });
    
    //Reset note previews to minimize bloating
    setShouldShowNotes([]);

    //Cleanup
    return (() => {
      clearInterval(updateClientsideDurationInterval);
      clearInterval(syncDurationInterval);
    })
  }, [currentWorkingQuestionIndex])

  const continueAfterShowingResults = () => {
    setShouldShowResultsPopup(false);
    window.location.hash = '';
    return;
  }

  const requestForMostRecent = () => {
    if(isRequestActive.current) return;

    axios
    .get('/api/meeting', { params: { meetingId: id } })
    .then(response => {
      if(response.status === 204) return;

      const meeting = response.data.meeting as Meeting;
      setFeedback(meeting.feedback);
      setInvitees(meeting.invitees);
      setWorkingQuestions(meeting.workingQuestions);
      isRequestActive.current = false;
    })
    isRequestActive.current = true;
  }

  React.useEffect(() => {
    requestId.current = window.setInterval(() => {
      requestForMostRecent();
      syncMinutes();
    }, 1000);

    return () => window.clearInterval(requestId.current);
  }, [])
  React.useEffect(() => {
    if(hash.includes('results') && !shouldShowResultsPopup){
      setShouldShowResultsPopup(true);
    }
    if(!hash.includes('results') && shouldShowResultsPopup){
      setShouldShowResultsPopup(false);
    }
  }, [hash]);

  //PATCH SANITIZE INVITEES
  React.useEffect(() => {
    const [ sanitizedInvitees, removedCount ] = sanitizeInvitees(invitees);
    if(removedCount){
      setInvitees(sanitizedInvitees);
    }
  }, [invitees]);

  return (
    <>
      <FullScreenLoadingOverlay show={loading} />
      { dateTimeEnded ?
        <div
          style={{
            margin: '15rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <h1
            style={{
              margin: '2rem auto',
              fontSize: '2.5rem'
            }}
          >
            This Qck has ended.
            Click the button below to check out how it went!
          </h1>
          <Button
            variant='contained'
            style={{
              fontSize: '2rem',
            }}
            onClick={() => window.location.href=`/${id}/evaluation`}
          >evaluation</Button>
        </div>
        :
          <>
            <div
              className={style.popupContainer}
              style={{
                display: shouldShowVotePopup || shouldShowResultsPopup || shouldShowGatherPopup ? 'flex': 'none'
              }}
            >
              {shouldShowGatherPopup && 
              <GatherPopup 
                setShouldShowVotePopup={setShouldShowVotePopup}
                duration={currentWorkingQuestionDuration}
                feedback={feedback[currentWorkingQuestionIndex]}
                invitees={invitees}
                currentWorkingQuestionIndex={currentWorkingQuestionIndex}
                endVoting={endVoting}
                ownerEmail={ownerEmail}
                setShouldShowGatherPopup={setShouldShowGatherPopup}
              />}
              {shouldShowVotePopup && 
              <GivePopup 
                setShouldShowVotePopup={setShouldShowVotePopup}
                type={type}
                submit={submitVote}
                team_name_or_working_question={workingQuestions[currentWorkingQuestionIndex].text}
              />}
              {shouldShowResultsPopup && 
              <ResultsPopup 
                feedback={feedback[currentWorkingQuestionIndex]}
                type={type}
                continueAction={continueAfterShowingResults}
                inviteesList={invitees}
                workingQuestionOrTeamName={workingQuestions[currentWorkingQuestionIndex].text}
                minute={minutes[currentWorkingQuestionIndex].text}
              />}
            </div>
            <div
              className={style.content}
            >
              <section className={style.actionButtonsWrapper}>
                <button
                  className={style.settingsButton}
                  onClick={() => {
                    window.location.href = `/${id}/meeting`;
                  }}
                >
                  <IconSettings />
                  <span>Settings</span>
                </button>

                <ToggleButton 
                 id='toggle-button'
                 checked={minutesContributeAllowed} 
                 onChange={(event) => updateMinutesContributeAllowed(event.value)}
                 onLabel='Contribution Enabled'
                 offLabel='Contribution Disabled'
                 className={style.toggleContributeButton}
                 tooltip={'When contribution is enabled all users with \"Contributer\" role can help with note taking.'} 
                 tooltipOptions={{
                   showDelay: 1000,
                   hideDelay: 300,
                   mouseTrack: true,
                   style: {
                     fontSize: '2rem'
                   }
                 }}
                />
              </section>

              <section className={style.workingQuestions}>
                {workingQuestions.map((wq, index) => {

                  if(wq.isBacklogged) return;

                  let startTimeDisplay = '';
                  let durationDisplay = '';
                  //const duration = index === currentWorkingQuestionIndex ? currentWorkingQuestionDuration : workingQuestions[index].duration;
                  const duration = workingQuestions[index].duration;
                  const startTime = workingQuestions[index].startDateTime;
                  if(!startTime){
                    startTimeDisplay = '--:--';
                  }else{
                    const asDate = new Date(startTime);
                    startTimeDisplay = `${parseNumber(asDate.getHours())}:${parseNumber(asDate.getMinutes())}`
                  }
                  durationDisplay = duration === undefined ? '--:--' : secondsToMMSS(duration);
                  return (
                  <section
                    className={`${style.workingQuestionContainer} ${index === currentWorkingQuestionIndex ? style.show : style.hide}`}
                    key={index}
                  >
                    <div
                      className={style.displayButton}
                      onClick={() => {
                        if(currentWorkingQuestionIndex !== index){
                          updateCurrentWorkingQuestionDuration(currentWorkingQuestionDuration, true);
                          changeCurrentWorkingQuestionIndex(index);
                        }
                      }}
                    >
                      <div></div>
                      <span className={style.workingQuestionText}>{wq.text || ''}</span>
                      <div
                        className={style.startDurationWrapper}
                      >
                        <div
                          className={style.startDurationContainer}
                        >
                          <span
                            className={style.start}
                          >Start: {startTimeDisplay}</span>
                          <span
                            className={style.duration}
                          >Duration: {index === currentWorkingQuestionIndex ? currentWorkingQuestionDurationDisplay : durationDisplay}</span>
                        </div>
                      </div>
                    </div>
                    <Editor
                      isCurrent={index === currentWorkingQuestionIndex}
                      onResultsClick={handleShowResultsClick}
                      onFeedBackClick={handleStartVotingClick}
                      onEditorChange={handleMinuteWrite}
                      minute={minutes[index]}
                      hasVotingEnded={!!feedback[currentWorkingQuestionIndex] && !!feedback[currentWorkingQuestionIndex].votingEndedDateTime}
                      hasAllPermissions
                    />
                    { index !== currentWorkingQuestionIndex && !!minutes[index].text &&
                      <section className={style.showNotesContainer}>
                        <div className={style.showNotesButtonContainer}>
                          <ToggleButton
                           id='toggle-button-notes'
                           checked={shouldShowNotes.includes(index)}
                           onChange={() => toggleShowNotes(index)}
                           onLabel='Hide notes'
                           offLabel='Show notes'
                           className={style.toggleContributeButton}
                           tooltip={'If you have written down some notes you can look at them without switching the current working question'} 
                           tooltipOptions={{
                             showDelay: 1000,
                             hideDelay: 300,
                             mouseTrack: true,
                             style: {
                               fontSize: '2rem'
                             }
                            }}
                          ></ToggleButton>
                        </div>
                        { shouldShowNotes.includes(index) &&
                          <section>
                            <CustomMarkdownRenderer className={style.notesField} remarkPlugins={[gfm]}>
                              {minutes[index].text}
                            </CustomMarkdownRenderer>
                          </section>
                        }
                      </section>
                    }
                  </section>
                )})}
              </section>

              <EndQckWarning endQck={endQck} cancel={() => setShouldShowWarningPopup(false)} shouldShowWarning={shouldShowWarningPopup} />
              <Button
                className={style.endButton}
                variant='contained'
                style={{
                  fontSize: '2rem',
                  padding: '0 4rem',
                  backgroundColor: 'black',
                  marginTop: '2rem'
                }}
                onClick={handleEndQckClick}
              >
                End qck
              </Button>
            </div>
          </>
      }
    </>
  ) 
}
