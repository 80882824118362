import * as React from 'react';
import style from './GivePopup.module.css';
import { MIN_QUESTION_ANSWER, MAX_QUESTION_ANSWER, TYPE_QUESTIONS_MAP, NUMBER_TYPE_TYPE_MAP, TYPE_HELPER_MAP} from '../../../../constants';
import {Button, Slider, inputAdornmentClasses} from '@mui/material';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {useMeeting} from '../../../../contexts/MeetingContext';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';

export default function GivePopup(props: {
  setShouldShowVotePopup?: React.Dispatch<React.SetStateAction<boolean>>;
  type: MeetingType;
  submit: (answers: Answer[], opinions: Opinion[], name?: string) => void | boolean;
  team_name_or_working_question?: string | undefined;
  showNameInput?: boolean
  standalone?: boolean;
}) {
  const QCK_VER = process.env.REACT_APP_QCK_VER;

  const { type } = props;
  const { t } = useTranslation();

  const questions = TYPE_QUESTIONS_MAP[type];

  const { id } =  useParams();

  const nameInputRef = React.useRef<HTMLInputElement>(null);

  const [ answers, setAnswers ] = React.useState<Answer[]>(Array.from({length: questions.length}).fill(5) as Answer[]);
  const [ opinions, setOpinions ] = React.useState<Opinion[]>(Array.from({length: questions.length}).fill("") as Opinion[]);
  const [ name, setName ] = React.useState<string>('');

  const textFieldRef = React.useRef<HTMLTextAreaElement>(null);

  const marksArray = [{value: MIN_QUESTION_ANSWER, label: MIN_QUESTION_ANSWER + ''}, {value: MAX_QUESTION_ANSWER, label: MAX_QUESTION_ANSWER + ''}]

  const handleSliderChange = (event: Event) => {
    const slider = event.target as { value: number, name: string } | null;

    if(!slider) return;

    const index = parseInt(slider.name);
    setAnswers((curr) => {
      const _new = [...curr];
      _new[index] = slider.value;
      return _new;
    })
  }

  const handleCheckboxChange = (event: CheckboxChangeEvent, index: number) => {
    setOpinions(curr => {
      const _new = [ ...curr ];
      _new[index] = event.checked ? null : "";
      return _new;
    })


    return;
  }

  const handleOpinionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = event.target as HTMLTextAreaElement;

    if(!textarea) return;

    const text = textarea.value;
    const index = parseInt(textarea.name);
    setOpinions((curr) => {
      const _new = [...curr];
      _new[index] = text || '';
      return _new;
    })

    resize(textarea);
  }

  //resizes textarea so that all the text is visible on the screen at the same time
  const resize = (textArea: HTMLTextAreaElement) => {
    if(textArea){
      textArea.style.height = 'auto';
      textArea.style.height = (textArea.scrollHeight) + 'px';
    }
  }

  const submit = () => {
    if(QCK_VER === '2'){
      props.submit(answers, opinions);
    }else if(QCK_VER === '1'){
      const result = props.submit(answers, opinions, name);
      if(!result){
        const inputField = nameInputRef.current!;
        inputField.reportValidity();
      }
    }
  }

  //Handles resize of all text areas every time user resizes screen
  React.useEffect(() => {
    const resizeAll = () => {
      const inputTextAreas = Array.from(document.querySelectorAll('[title="opinion-input"]'));
      inputTextAreas.forEach((textarea) => resize(textarea as HTMLTextAreaElement));
    }
    window.addEventListener('resize', resizeAll);
    return () => window.removeEventListener('resize', resizeAll);
  }, [])

  return (
    <div
      className={style.container}
    >
      <h1 className={style.meetingType}>
        {NUMBER_TYPE_TYPE_MAP[type]}
      </h1>
      <h2 className={`${style.heading2} ${style.teamName}`}>
        {props.team_name_or_working_question || ''}
      </h2>
      
      <h1 className={style.heading1}>
        {t('Your feedback will be appreciated!')}
      </h1>
      {props.showNameInput &&
        <>
          <h2>
            {t('Your name*')}
          </h2>
          <input ref={nameInputRef} placeholder={t('Name') || ''} className={style.nameInput} onChange={(e) => setName(e.target.value)} value={name} required/>
        </>
      }

      <h2 className={style.heading2}>
        {t(`Answer with a number between ${MIN_QUESTION_ANSWER} and ${MAX_QUESTION_ANSWER}, depending on how much you agree with the statement`)}
      </h2>
      
      <section className={style.sliderContainer}>
        {questions.map((q, index) => (
          <div className={style.questionContainer} key={index}>
            <p className={style.question}>
              {t(q)}
            </p>
            <Slider
              onChange={handleSliderChange}
              name={index + ''}
              value={answers[index]}
              step={1}
              min={MIN_QUESTION_ANSWER}
              max={MAX_QUESTION_ANSWER}
              disabled={opinions[index] === null}
              marks={[...marksArray, {value: answers[index], label: answers[index]}]}
              className={style.slider}
            />
            <textarea
              ref={textFieldRef}
              className={style.text}
              name={index + ''}
              value={opinions[index] === null ? t("Not applicable") || "" : opinions[index] || ""}
              onChange={handleOpinionChange}
              placeholder={t(TYPE_HELPER_MAP[type][index]) || ''}
              title='opinion-input'
              disabled={opinions[index] === null}
            >
            </textarea>
            <div className={style.dontApplyButtonContainer}>
              <Checkbox checked={opinions[index] === null} onChange={(e) => handleCheckboxChange(e, index)}/>
              <p>{t('This question doesn\'t apply to me')}</p>
            </div>
          </div>
        ))}
      </section>

      <div className={style.submitButtonContainer}>
        <Button
          variant='contained'
          style={{
            fontSize: '1.2rem',
            paddingLeft: '5rem',
            paddingRight: '5rem',
          }}
          onClick={submit}
        >
          {t('Submit')}
        </Button>
        { !props.standalone && <Button
          variant='contained'
          style={{
            fontSize: '1.2rem',
            paddingLeft: '5rem',
            paddingRight: '5rem',
          }}
          onClick={() => props.setShouldShowVotePopup && props.setShouldShowVotePopup(false)}
        >
          {t('Back')}
        </Button> }
      </div>

    </div>
  )
}

