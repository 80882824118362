
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

/**
  * Sees if email is valid according to this regex /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  * @returns is valid state
  */
export default function validateEmail(email : string) : boolean {
  return emailRegex.test(email);
}
