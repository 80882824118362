import * as React from "react";
import style from './Results.module.css';
import { ToggleButton } from "primereact/togglebutton";
import { useTranslation } from "react-i18next";
import ResultsComponent from "../../../components/Meeting/Voting/Results/Results";
import {Button} from "primereact/button";
import ResultsPrint from "../../../components/Meeting/Voting/ResultsPrint/ResultsPrint";
import { download, print } from "../../../functions/pdfSaver";

export default function Results(props: {
  meeting: QuickMeeting
  changeAreResultsPublic: (newState: boolean) => void
  isOwner: boolean
}) {

  const { t } = useTranslation();

  const printContentRef = React.useRef<HTMLDivElement>(null);

  const toggleAreResultsPublic = () => {
    props.changeAreResultsPublic(!props.meeting.areResultsPublic);
  }

  return (
    <>
      <h1 className={style.h1}>
        {t('Your questionnaire')}
      </h1>
      <h2 className={style.h2}>
        {
          !props.meeting.workingQuestion.text || props.meeting.workingQuestion.text?.length === 0 ? 
            props.meeting.type === 1 || props.meeting.type === 5 ? t('You don\'t have a working question') :
            t('You didn\'t enter a team name')
          : props.meeting.workingQuestion.text
        }
      </h2>

      <main className={style.content}>
        <div className={style.toggleResultsButtonContainer}>
          <Button className={style.saveButton} onClick={() => download(printContentRef.current, props.meeting.workingQuestion.text || undefined)}>
            {t('Save PDF')}
          </Button>
          <Button className={style.printButton} onClick={() => print(printContentRef.current)}>
            {t('Print')}
          </Button>
          {props.isOwner &&
            <ToggleButton 
              checked={props.meeting.areResultsPublic}
              onLabel={t('Public') || ''}
              offLabel={t('Hidden') || ''}
              onClick={toggleAreResultsPublic}
              tooltip={t('If this is on, everyone can see the results of the questionnaire') || ''}
              tooltipOptions={{
                mouseTrack: true,
                style: {
                  fontSize: '1.5rem'
                },
                showDelay: 600
              }}
            />
          }
        </div>

        <section className={style.basicStats}>
          <div>
            {t('People invited')}
            <span>
              {props.meeting.invitees.length}
            </span>
          </div>
          <div>
            {t('People answered')}
            <span>
              {Object.keys(props.meeting.feedback.answers).length}
            </span>
          </div>
        </section>

        <section className={style.resultsContainer} >
          {Object.keys(props.meeting.feedback.answers).length > 0 ? 
            <ResultsComponent type={props.meeting.type} feedback={props.meeting.feedback} emailColorMap={Object.fromEntries(props.meeting.invitees.map(({email, color}) => [email, color]))}/>
            : 
            <p className={style.noResults}>
              {t('No one has submitted their feedback yet!') || '' }
            </p>
          }
        </section>
        </main>
        <ResultsPrint
          type={props.meeting.type}
          feedback={props.meeting.feedback}
          emailColorMap={Object.fromEntries(props.meeting.invitees.map(({email, color}) => [email, color]))} 
          contentRef={printContentRef}
          workingQuestionOrTeamName={props.meeting.workingQuestion.text}
        />
    </>
  )
}

