import * as React from 'react';
import { useState, useEffect } from 'react';
import { AuthContext } from '../hooks/useAuth';
import axios from 'axios';

function MyAccount() {

  const { user, loading } = React.useContext(AuthContext)!;

  return (
    <>
      {!loading &&
        <a
          href={user ? '/about-my-account/' + user : '/login'}
          id = 'nav-account-link'
        >
          {
            user || 'Login'
          }
        </a>
      }
    </>
  );
}
export default MyAccount;
