import * as React from 'react';
import { useParams } from 'react-router-dom'; import style from './Agenda.module.css';
import WorkingQuestionInput from '../WorkingQuestionInput';
import axios, { AxiosResponse } from 'axios';
import { OrderList } from 'primereact/orderlist';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';  // theme
import WorkingQuestionsOrderList from '../CustomOrderList/WorkingQuestionsOrderList';
import {ROLE_PERMISSION_MAP} from '../../../constants';
import WorkingQuestionPopover from '../WorkingQuestionPopover';
import { OrderOperation } from '../../../enums';
import reorder from '../../../functions/reorder-array';

export default function Agenda(props: {
  workingQuestions: WorkingQuestion[]
  setWorkingQuestions?: (workingQuestions: WorkingQuestion[]) => void
  dateTimeEnded: number | undefined
  userRole: Role
  includeBacklog?: boolean
}){

  const userEmail = sessionStorage.getItem('qckEmail');

  const [ workingQuestions, setWorkingQuestions ] = React.useState<WorkingQuestion[]>(props.workingQuestions);

  const [ changes, setChanges ] = React.useState<AgendaChange[]>([]);
  const [ lastUpdate, setLastUpdate ] = React.useState<number>(Date.now());

  const [ focusedWqIndex, setFocusedWqIndex ] =  React.useState<number>();

  const syncIntervalId = React.useRef<number>();
  const isRequestActive = React.useRef<boolean>(false);

  const sync = () => setChanges(curr => {
    if(isRequestActive.current) return curr

      //Simplest way i could think of of avoiding 
      //doing a same swap twice - fml
      const filteredChanges = curr.filter((change) => !change.dateTimeAdded || Date.now() - 2000 < change.dateTimeAdded  );

      axios
      .post('/api/meeting-agenda', { 
        changes: filteredChanges,
        lastUpdate,
        id
      })
      .then((response) => {
        if(response.status === 200){
          setChanges(curr_after => {
            if(curr_after.length <= 0){
              console.log(`INFO: In agenda sync callback if no local changes: Updating local state:`);
              setWorkingQuestions([...response.data]);
              //console.log(`INFO: In agenda sync callback if no local changes: Updating router state:`);
              //console.log(response.data);
              //console.log(`INFO: is setWorkingQuestions passed?: ${!!props.setWorkingQuestions}`);
              props.setWorkingQuestions && props.setWorkingQuestions([...response.data]);
              setLastUpdate(Date.now());
            }
            return curr_after;
          })
        }
        isRequestActive.current = false;
      }).catch(() => window.location.reload());
      isRequestActive.current = true;
      return [];
  })

  React.useEffect(() => {
    syncIntervalId.current = window.setInterval(sync, 1000);

    return () => {
      window.clearInterval(syncIntervalId.current)
    };
  }, []);

  const addChange = (type: AgendaChangeType, props: any, dateTimeAdded?: number) => {
    console.log("Adding change", type, props, dateTimeAdded);
    setChanges(curr => [...curr, { type, props, dateTimeAdded }]);
  }

  const { dateTimeEnded } = props;

  const { id } = useParams();

  const changeBacklogStatus = (index: number, newState: boolean) => {
    setWorkingQuestions(curr => {
      const _new = [ ...curr ];
      _new[index].isBacklogged = newState;
      addChange('backlog', { index, newState });
      return _new;
    })
  }

  const changeWorkingQuestionType = (index: number, type: WorkingQuestionType) => {
    setWorkingQuestions(curr => {
      const _new = [...curr];
      _new[index].type = type;
      addChange('texttype', { index, type });
      return _new;
    })
  }

  const changeWorkingQuestionOwnership = (index: number, take: boolean) => {
    //take meaning current user either tries to take ownership or tries to lose it
    const currentUserEmail = sessionStorage.getItem('qckEmail') || "";
    setWorkingQuestions(curr => {
      const _new = [...curr];
      _new[index].owner = take ? currentUserEmail : undefined;
      addChange('owner', { index, newOwner: take ? currentUserEmail : undefined });
      return _new;
    })
  }

  const setWorkingQuestionText = (index: number, newText: string) => {
    setWorkingQuestions(curr => {
      const _new = [...curr];
      _new[index].text = newText;
      addChange('texttype', { index, text: newText});
      return _new;
    })
  }

  /** @deprecated using backlog instead of deletion now*/
  const removeWorkingQuestion = (index: number) => {
    setWorkingQuestions((curr) => {
      const _new = [...curr];
      _new.splice(index, 1);
      //axios.delete('/api/meeting-agenda', { params: { id, index }}).then(handleLongPoolResponse);
      return _new;
    })
  }

  const addWorkingQuestion = (text = '', type: WorkingQuestionType = '' ) => {
    setWorkingQuestions((curr) => [...curr, {text, type, creator: userEmail || ""}] )
    addChange('add', { text, type });
  }

  const handleReorder = (indexes: [number, number?], op: OrderOperation) => {

    const [newWorkingQuestions, new_index] = reorder(workingQuestions, indexes, op);

    setWorkingQuestions(newWorkingQuestions);

    //Slight timeout is workaround, dont worry
    setTimeout(() => {
      setFocusedWqIndex(new_index)
      const element = document.getElementById(`wq-ignore-${new_index}`);
      if(op === OrderOperation.START || op === OrderOperation.END)
        element && element.scrollIntoView({ behavior: 'smooth' })
    }, 5);

    addChange('order', {
      indexes, op
    }, Date.now());
      
  }

  const workingQuestionInputTemplate = (workingQuestion: WorkingQuestion | WorkingQuestionWithIndex): React.ReactNode => {
    let index;
    
    if((workingQuestion as WorkingQuestionWithIndex).index !== undefined){
      index = (workingQuestion as WorkingQuestionWithIndex).index;
    }else{
      index = workingQuestions.indexOf(workingQuestion);
    }

    return (
      <WorkingQuestionInput
        key={index}
        workingQuestionIndex={index}
        saveChanges={setWorkingQuestionText}
        changeType={changeWorkingQuestionType}
        workingQuestion={workingQuestion}
        hasMeetingEnded={!!dateTimeEnded}
        remove={removeWorkingQuestion}
        isCurrentUserCreator={workingQuestion.creator === userEmail}
        currentUserRole={props.userRole}
        changeWorkingQuestionOwnership={changeWorkingQuestionOwnership}
        changeBacklogStatus={changeBacklogStatus}
      />
    )
  }

  return (
    <>
      <section
        className={style.wqSection}
      >
        <WorkingQuestionsOrderList
          workingQuestions={workingQuestions.map((wq, index) => {
            const wq_w_index = { ...wq, index } as WorkingQuestionWithIndex;
            return wq_w_index;
          }).filter(wq => !wq.isBacklogged)}
          workingQuestionInputTemplate={workingQuestionInputTemplate}
          addChange={addChange}
          focusedWqIndex={focusedWqIndex}
          setFocusedWqIndex={setFocusedWqIndex}
          handleReorder={handleReorder}
          hasReorderPermissions={ROLE_PERMISSION_MAP[props.userRole].includes('agenda.reorder')}
        />
      </section>
      <div
        className={style.newButtonWrapper}
        style={{
          display: dateTimeEnded ? 'none' : 'flex'
        }}
      >
        <Button
          style={{
            fontSize: '1.5rem',
            margin: '1rem 1rem 0 auto'
          }}
          onClick={() => addWorkingQuestion()}
        >+</Button>
      </div>
      {/*backlog*/}
      {props.includeBacklog && workingQuestions.filter((wq) => wq.isBacklogged).length > 0 && <>
        <h1 className={style.backlogHeading}>
        <span style={{marginRight: '1rem'}}>Backlog</span> <WorkingQuestionPopover popoverText={'(i)'} contentText={'Working questions in the backlog won\'t be discussed in the meeting. '} style={{ fontSize: '3rem'}}/>

        </h1>
        {//map backlog
          workingQuestions.map((wq, index) => (
            wq.isBacklogged ? 
          <WorkingQuestionInput
            key={index}
            workingQuestionIndex={index}
            saveChanges={setWorkingQuestionText}
            changeType={changeWorkingQuestionType}
            workingQuestion={wq}
            hasMeetingEnded={!!dateTimeEnded}
            remove={removeWorkingQuestion}
            isCurrentUserCreator={wq.creator === userEmail}
            currentUserRole={props.userRole}
            changeWorkingQuestionOwnership={changeWorkingQuestionOwnership}
            changeBacklogStatus={changeBacklogStatus}
          /> : <></>
        ))}
      </>}
    </>
  )
}
