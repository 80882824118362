import React, {useEffect, useState} from 'react';
import style from './Slider.module.css';
import { MAX_QUESTION_ANSWER } from '../../../../constants';

export default function Slider(props:{
  feedback: IndexedFeedback,
  emailColorMap: Record<string, string>
  index: number
}) {

  const { feedback, emailColorMap, index } = props;

  const [ sliderKey, setSliderKey ] = useState<number>(0);

  const reloadSlider = () => setSliderKey(curr => curr + 1);

  const sliderRef = React.useRef<HTMLDivElement>(null);

  const getSliderWidth = (): number => {
    if(!sliderRef.current) return -1;

    return sliderRef.current.clientWidth;
  }

  //Calcs to determine which votes are repeating and need to be grouped
  //Returns a list of objects as {answerValue: number, letters: []}
  type ValuesAndLettersWithColors = {
    answerValue: number,
    lettersWithColors: {
      color: string,
      letter: string
    }[]
  }[];

  const createValuesAndLetters = (feedbackQuestionIndex: number): ValuesAndLettersWithColors => {

    //Answer value to array of first letters of emails map :)
    const list: Record<number, {letter: string, color: string}[]> = {};

    for(const email in feedback.answers){
      if(feedback.opinions[email][feedbackQuestionIndex] === null){
        continue;
      }
      const value = feedback.answers[email][feedbackQuestionIndex];
      if(list[value] === undefined){
        list[value] = [];
      }

      list[value].push({
        letter: email.charAt(0),
        color: emailColorMap[email]
      });
      
    }

    const valuesAndLetters: ValuesAndLettersWithColors = [];
    for(const value in list){
      valuesAndLetters.push({
        answerValue: parseInt(value),
        lettersWithColors: list[value]
      })
    }
    
    return valuesAndLetters;
  }

  function Circle (props: {
    letter: string
    color: string
    yOffset: number
    style?: any
  }) {
    return (
      <span 
        style={{
          backgroundColor: props.color,
          transform: `translateY(${-props.yOffset * 5}px)`,
          ...props.style
        }} 
        className={style.circle}
      >
        {props.letter}
      </span>
    )
  }

  function CircleGroup (props: {
    children: React.ReactElement[]
    leftOffset: number
  }) {


    const offsetPixValue = (() => {
      const val = props.leftOffset * getSliderWidth() * MAX_QUESTION_ANSWER * 0.01;
      return val >= getSliderWidth() - 20 ?
        getSliderWidth() - 20
        : val;
    })();

    return (
      <div 
        className={style.circleGroup}
        style={{
          transform: `translateX(${offsetPixValue}px)`
        }}
      >
        {props.children}
      </div>
    )
  }

  useEffect(() => {

    window.addEventListener('resize', reloadSlider);

    reloadSlider();

    return () => window.removeEventListener('resize', reloadSlider);
  }, [])

  return (
    <div className={style.slider} ref={sliderRef} key={sliderKey}>
      {createValuesAndLetters(index).map((valuesAndLettersWithColors, _) => {
        return (
          <CircleGroup key={_} leftOffset={valuesAndLettersWithColors.answerValue}>
            {valuesAndLettersWithColors.lettersWithColors.map((letterWithColor, index) => (
              <Circle letter={letterWithColor.letter} color={letterWithColor.color} key={index} yOffset={index}/>
            ))}
          </CircleGroup>
        )
      })}
    </div>
  )
}
