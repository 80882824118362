import * as React from 'react';
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { AuthContext } from '../hooks/useAuth';
import axios from 'axios';

export const ProtectedRoutes = () => {

  const { user, loading } = React.useContext(AuthContext)!;
  const location = useLocation();

  return (
    loading ? <>Loading...</> :
    user ? <Outlet /> : <Navigate to="/login" state={{ afterLoginLocation: location.pathname }} />
  )
}
