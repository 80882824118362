import * as React from "react";

import style from './Agenda.module.css';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useTranslation } from "react-i18next";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { useParams, useNavigate } from "react-router-dom";
import CopyLinkButton from "../../../components/Meeting/CopyLinkButton";
import QRCode from 'react-qr-code';

export default function Agenda(props: {
  meeting: QuickMeeting;
  changeWorkingQuestion: (newWorkingQuestion: string) => Promise<void>
}) {

  const baseUrl = process.env.REACT_APP_BASE_URL;

  //At what width do i wanna stop showing the entire invitation link
  const linkConversionWidth = 500;

  const [ viewportWidth, setViewportWidth ] = React.useState(window.innerWidth);

  const [ workingQuestionInputFieldValue, setWorkingQuestionInputFieldValue ] = React.useState(props.meeting.workingQuestion.text || '');

  const [ isEditPrompted, setIsEditPrompted ] = React.useState<boolean>(false);

  const { id } = useParams();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const invitationLink = `${baseUrl}qck/${id}/vote`
  
  const workingQuestionInputFieldRef = React.useRef<HTMLInputElement>(null);

  const handleResultsButtonClick = () => {
    navigate(`/qck/${id}/results`);
  }

  React.useEffect(() => {
    const handleResize = () => setViewportWidth(window.innerWidth); 

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleWorkingQuestionInputChange = (event: React.ChangeEvent) => {
    const text = (event.target as HTMLInputElement).value;

    setWorkingQuestionInputFieldValue(text);
  }

  const handleSaveWorkingQuestion = () => {
    const field = workingQuestionInputFieldRef.current as HTMLInputElement;

    setIsEditPrompted(false);
    props.changeWorkingQuestion(field.value);
  }

  const handleSkip = () => {
    props.changeWorkingQuestion('');
  }

  const toggleIsEditPrompted = () => {
    setIsEditPrompted(curr => {
      if(curr) setWorkingQuestionInputFieldValue(props.meeting.workingQuestion.text || '');
      return !curr;
    });
  }

  React.useEffect(() => {
    if(isEditPrompted && workingQuestionInputFieldRef.current){
      workingQuestionInputFieldRef.current.focus();
    }
  }, [isEditPrompted]);

  React.useEffect(() => {
    console.log(props.meeting);
  }, [props.meeting])

  return (
    <>
      <h1 className={style.h1} >
        { props.meeting.type !== 5 ?
            t('Enter team name')
          :
            t('Enter your working question')
        }
      </h1>
      { props.meeting.type === 5 && 
        <h2 className={style.h2} >
          {t('Or go straight to gathering feedback')}
        </h2>
      }

      <section className={style.agendaSection} >
        <InputText 
          ref={workingQuestionInputFieldRef}
          value={workingQuestionInputFieldValue}
          onChange={handleWorkingQuestionInputChange}
          disabled={props.meeting.workingQuestion.text !== null && !isEditPrompted}
          placeholder={
            props.meeting.type === 5 ?
              props.meeting.workingQuestion.text === null || isEditPrompted
                ?  t("Your Working Question") || ''
                : t("You didn\'t input a working question") || ''
            : 
              props.meeting.workingQuestion.text === null || isEditPrompted
                ?  t("Your team name") || ''
                : t("You didn\'t input a team name") || ''
          } 
          style={{
            width: '100%',
            fontSize: '2rem',
            paddingLeft: '2rem'
          }}
        />
        {props.meeting.workingQuestion.text === null 
          ? <div className={style.buttonsContainer}>
          { props.meeting.type === 5 && <Button onClick={handleSkip} className={style.Button}>Skip</Button> }
          <Button disabled={!workingQuestionInputFieldValue} onClick={handleSaveWorkingQuestion} className={style.Button}>{t('Continue')}</Button>
        </div>
        : !isEditPrompted 
          ? <div style={{justifyContent: 'flex-end'}} className={style.buttonsContainer}>
            <Button onClick={toggleIsEditPrompted} className={style.Button}>{t('Edit')}</Button>
          </div>
          : <div className={style.buttonsContainer}>
            <Button onClick={toggleIsEditPrompted}className={style.Button}>{t('Cancel')}</Button>
            <Button disabled={props.meeting.type !== 5 && !workingQuestionInputFieldValue} onClick={handleSaveWorkingQuestion}className={style.Button}>{t('Save')}</Button>
          </div>
        }
      </section>
      
      <section 
        className={`${style.inviteSection} ${props.meeting.workingQuestion.text !== null ? style.hide : style.show}`}
      >
        <h1 className={`${style.h1} ${style.inviteHeader}`}>
          {t('Invite people to participate in your questionnaire')}
        </h1>
        <h2 className={`${style.h2} ${style.inviteHeader2}`}>
          {t('Send this link to everyone that you want to particiate in answering your questionnaire')}
        </h2>
        <div className={style.inviteLinkContainer}>
          <a href={invitationLink} target="_blank" rel='noreferrer'>
            {viewportWidth > linkConversionWidth ? 
              invitationLink : t('Invitation link')
            }
          </a>
          <CopyLinkButton
            text={t("Link copied succesfully")}
            link={invitationLink}
          />
        </div>

        <h2 className={`${style.h2} ${style.inviteHeader2}`}>
          {t('Send this code and enter it on qck.app')}
        </h2>
        <div className={style.inviteLinkContainer}>
          <a>{props.meeting.id}</a>
          <CopyLinkButton
            text={t("Link copied succesfully")}
            link={props.meeting.id}
          />
        </div>

        <h2 className={`${style.h2} ${style.inviteHeader2}`}>
          {t('Scan the QR code')}
        </h2>
        <div className={style.inviteLinkContainer}>
          <QRCode 
            value={invitationLink} 
            bgColor="#ececec"
            style={{ marginTop: 20, marginBottom: 20 }}
            size={200}
            id="qr-code"
          />
        </div>
        
        <div className={`${style.resultsButtonDiv} ${style.buttonsContainer}`}>
          <Button
            onClick={handleResultsButtonClick}
          >
            {t('Go to results')}
          </Button>
        </div>
      </section>
    </>
  )
}
