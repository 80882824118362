import * as React from 'react';

import style from './ResultsPopup.module.css';
import Results from './Results';
import { Button } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ResultsPrint from '../ResultsPrint/ResultsPrint';
import {useOverridePrintListener, print, download} from '../../../../functions/pdfSaver';

export default function ResultsPopup(props: {
  type?: MeetingType,
  feedback?: IndexedFeedback
  workingQuestionOrTeamName?: string;
  continueAction: () => void
  inviteesList?: Invitee[]
  minute?: string
  id?: string
}) {

  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(true);
  const printContentRef = React.useRef<HTMLDivElement>(null);

  const [type, setType] = React.useState<MeetingType>(props?.type || 1);
  const [feedback, setFeedback] = React.useState<IndexedFeedback>(props?.feedback || {answers: {}, opinions: {}});
  const [inviteesList, setInviteesList] = React.useState<Invitee[]>(props.inviteesList || []);

  const [id, setId] = React.useState<string>(props.id || '');

  React.useEffect(() => {
    if(
      !(
          Object.keys(feedback.answers).length <= 0 
       || Object.keys(feedback.opinions).length <= 0 
       && props.id
      )){
      return;
    }

    axios
    .get('/api/meeting', { params: { meetingId: id } })
    .then((response) => {
      const meeting = response.data.meeting as Meeting;
      setType(meeting.type);
      setFeedback(meeting.feedback[meeting.currentWorkingQuestionIndex]);
      setInviteesList(meeting.invitees);
      setLoading(false);
    })
  }, [])

  useOverridePrintListener(() => {
    print(printContentRef.current);
  });

  return (
    <>
      <div className={style.container}>
        <h1 className={style.heading1}>
          {props.workingQuestionOrTeamName}
        </h1>
        <div className={style.saveButtonsContainer}>
          <Button 
            variant='contained'
            className={style.saveButton}
            onClick={() => download(printContentRef.current, props.workingQuestionOrTeamName)}
          >{t('Save PDF')}</Button>
          <Button 
            variant='contained'
            onClick={() => print(printContentRef.current)}
          >{t('Print')}</Button>
        </div>
        <Results type={type} feedback={feedback} emailColorMap={Object.fromEntries(inviteesList.map(({email, color}) => [email, color]))}/>

        <Button
          variant='contained'
          className={style.continueButton}
          onClick={props.continueAction}
        >Continue</Button>
        
      </div>

      <ResultsPrint 
        type={type} 
        feedback={feedback} 
        emailColorMap={Object.fromEntries(inviteesList.map(({email, color}) => [email, color]))}
        workingQuestionOrTeamName={props.workingQuestionOrTeamName || ''}
        contentRef={printContentRef}
        minutes={props.minute}
      />
    </>
  )
}

