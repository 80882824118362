import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../../Navbar';
import '../../styles/aboutMyAccount.css';
import UserAgreementLink from '../../components/UserAgreementLink';
import style from './MyAccount.module.css';
import axios from 'axios';
import { AuthContext } from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

function AboutMyAccount() {
  const [ isLoading, setIsLoading ] = React.useState(false);

  const { user, loading, logout } = React.useContext(AuthContext)!;

  const navigate = useNavigate();
  const { email } = useParams();
  const { t } = useTranslation();

  const baseURL = process.env.REACT_APP_BASE_URL;

  const navigateToDashboard = () => {
    window.location.href = '/dashboard'
  }

  return (
    <>
      <Navbar />
      <main
        className={style.main}
      >
        <div
          className={style.loadingOverlay}
          style={{
            display: isLoading ? 'block' : 'none'
          }}
        ></div>
        <span
          className={style.heading}
        >
          {t('Your email')}:
        </span>
        <span
          className={style.email}
        >
          {email}
        </span>

        <ul
          className={style.actionButtonsContainer}
        >
          <li
            className={style.buttonContainer}
          >
            <p
              className={style.buttonLabel}
            >
              {t('Concerned that someone other than you is using your account?')}
            </p>
            <button
              className={style.button}
              onClick={logout}
            >
              {t('Log me out from any device')}
            </button>
          </li>
          <li
            className={style.buttonContainer}
          >
            <p
              className={style.buttonLabel}
            >
              {t('Want to look at your regular meetings and previous questionnaires?')}
            </p>
            <button
              className={style.button}
              onClick={navigateToDashboard}
            >
              {t('Go to Dashboard')}
            </button>
          </li>
        </ul>
      </main>
      <UserAgreementLink />
    </>
  );
}
export default AboutMyAccount;
