import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      sometext: 'sometext',
    },
  },
  de: {
    translation: {
      'Enter Code': 'Code Eingeben',
      'To Voting': 'Zur Befragung',
      'Vote ': 'Senden',
      Continue: 'Weiter',
      Results: 'Ergebnis',
      Voters: 'Teilnehmende',
      save: 'speichern',
      Close: 'Schliessen',
      Refresh: 'Wiederholen',
      'Enter team name': 'Teamnamen eingeben',
      'Enter your working question': 'Geben Sie Ihre Arbeitsfrage ein',
      'Or go straight to gathering feedback': 'Oder gehen Sie direkt zum Sammeln von Feedback',
      'Your Working Question': 'Ihre Arbeitsfrage',
      'You didn\'t input a working question': 'Sie haben keine Arbeitsfrage eingegeben',
      'Your team name': 'Ihr Teamname',
      'You didn\'t input a team name': 'Sie haben keinen Teamnamen eingegeben',
      'Invite people to participate in your questionnaire': 'Laden Sie Personen ein, an Ihrem Fragebogen teilzunehmen',
      'Send this link to everyone that you want to participate in answering your questionnaire': 'Senden Sie diesen Link an alle, die an der Beantwortung Ihres Fragebogens teilnehmen sollen',
      'Invitation link': 'Einladungslink',
      'Link copied succesfully': 'Link erfolgreich kopiert',
      'Send this code and enter it on qck.app': 'Senden Sie diesen Code und geben Sie ihn auf qck.app ein',
      'Scan the QR code': 'Scannen Sie den QR-Code',
      'Go to results': 'Zu den Ergebnissen gehen',
      'Create new questionnaire': 'Neue Befragung erstellen',
      'Your questionnaire': 'Ihr Fragebogen',
      'You don\'t have a working question': 'Sie haben keine Arbeitsfrage',
      'You didn\'t enter a team name': 'Sie haben keinen Teamnamen eingegeben',
      'Public': 'Öffentlich',
      'Hidden': 'Versteckt',
      'If this is on, everyone can see the results of the questionnaire': 'Wenn dies aktiviert ist, können alle die Ergebnisse des Fragebogens sehen',
      'People invited': 'Eingeladene Personen',
      'People answered': 'Antwortende Personen',
      'No one has submitted their feedback yet!': 'Noch hat niemand sein Feedback eingereicht!',
      'Custom duration': 'Individuelle Dauer',
      'The Questionnaire has been created!': 'Die Befragung wurde erstellt!',
      'Save all links and codes': 'Speichern Sie alle Links und Codes',
      'You cannot revisit this page':
        'Wenn Sie diese Seite verlassen, kann sie nicht mehr aufgerufen werden!',
      'To access the questionnaire':
        'So können Sie an der Befragung teilnehmen:',
      'Enter this code on qck.app': 'Geben Sie diesen Code unter qck.app ein',
      'Open this link': 'Öffnen Sie diesen Link',
      'To see the results': 'Ergebnisse der Befragung aufrufen',
      'Click this link': 'Öffnen Sie diesen Link',
      'Scan the QR code ': 'Scannen Sie den Code',
      'The result meets the objective: I know exactly what I have to do and until when.':
        'Das Ergebnis bringt uns weiter: Ich weiss, was ich bis wann zu tun habe.',
      "What's missing here?": 'Was fehlt zu diesem Punkt?',
      'The process, how we have achieved the result, was efficient.':
        'Der Prozess, wie wir das Ergebnis erarbeitet haben, war effizient.',
      'What do we want to pay attention to next time?':
        'Worauf wollen wir uns das nächste Mal achten?',
      'We have discussed constructively (even in controversial situations) and everyone was actively involved.':
        'Wir haben auch kontroversielle Themen konstruktiv diskutiert. Alle haben sich eingebracht.',
      'I deliver my contribution at the agreed time in the agreed quality.':
        'Ich liefere meinen Beitrag zum vereinbarten Zeitpunkt in der vereinbarten Qualität.',
      'What else would help me to contribute reliably?':
        'Was würde mir noch helfen, meinen Beitrag zuverlässig zu erbringen?',
      'By using the services of this site, I agree to the':
        'Mit der Nutzung der Leistungen von qck.app akzeptiere ich die',
      'End User License Agreement': 'Nutzerbedingungen',
      'End-User License Agreement': 'Endbenutzer-Lizenzvertrag',
      'Last updated: 1st February': 'Zuletzt aktualisiert: 1. Februar 2023',
      'This End-User License Agreement (referred to as the "EULA") is a legally binding agreement between you, the Licensee, an individual customer or entity, and the Syntegra KG and Aux Code OOD, the company, and the author of Qck.app, the Software, which may include associated media, printed materials, and online or electronic documentation. This Agreement is a legally binding contract that includes terms that limit your legal rights and Licensors\' liability to you, and shall govern all access to and use of this Software. You hereby agree, without limitation or alteration, to all the terms and conditions contained herein.':
        'Dieser Endbenutzer-Lizenzvertrag (im Folgenden als "EULA" bezeichnet) ist ein rechtsverbindlicher Vertrag zwischen Ihnen, dem Lizenznehmer, einem einzelnen Kunden oder einer juristischen Person, und der Syntegra KG und Aux Code OOD, dem Unternehmen und dem Autor von Qck.app, der Software, die zugehörige Medien, gedruckte Materialien und Online- oder elektronische Dokumentation enthalten kann. Diese Vereinbarung ist ein rechtsverbindlicher Vertrag, der Bedingungen enthält, die Ihre gesetzlichen Rechte und die Haftung der Lizenzgeber Ihnen gegenüber einschränken, und regelt den gesamten Zugriff auf und die Nutzung dieser Software. Sie erklären sich hiermit ohne Einschränkung oder Änderung mit allen hierin enthaltenen Bestimmungen und Bedingungen einverstanden.',
      'By installing, copying, or otherwise using the Licensed Product (Software), the Licensee agrees to be bound by the terms and conditions outlined in this EULA. However, if the Licensee does not agree to the terms and conditions outlined in this EULA, the said Licensee may not download, install, or use Software.':
        'Durch die Installation, das Kopieren oder die anderweitige Nutzung des lizenzierten Produkts (Software) erklärt sich der Lizenznehmer mit den in dieser EULA dargelegten Bedingungen und Bestimmungen einverstanden. Wenn der Lizenznehmer jedoch nicht mit den in dieser EULA dargelegten Bedingungen einverstanden ist, darf er die Software nicht herunterladen, installieren oder verwenden',
      Definitions: 'Definitionen',
      '"EULA" shall refer to this End-User-License-Agreement, including any amendment to this Agreement.':
        '"EULA" bezieht sich auf diese Endbenutzer-Lizenzvereinbarung, einschließlich aller Änderungen zu dieser Vereinbarung.',
      '"Licensee" shall refer to the individual or entity that downloads and uses the Software.':
        'Der Begriff "Lizenznehmer" bezieht sich auf die natürliche oder juristische Person, die die Software herunterlädt und verwendet.',
      '"Licensor" shall refer to the company or author, Syntegra KG and Aux Code OOD, located at 1040 Vienna, Brahmsplatz 4/19.':
        'Der Begriff "Lizenzgeber" bezieht sich auf die Firma oder den Autor, Syntegra KG und Aux Code OOD, mit Sitz in 1040 Wien, Brahmsplatz 4/19.',
      '"Software/Licensed product" shall mean Qck.app, the Licensed Product provided pursuant to this EULA.':
        '"Software/Lizenziertes Produkt" bezeichnet Qck.app, das lizenzierte Produkt, das gemäß diesem EULA bereitgestellt wird.',
      'Grant of License': 'Erteilung der Lizenz',
      'Subject to the terms of this EULA, the Syntegra KG and Aux Code OOD hereby grants to the Licensee, a royalty-free, revocable, limited, non-exclusive license during the term of this EULA to possess and to use a copy of the Software. The Software is being distributed by Syntegra KG and Aux Code OOD. Licensee is not allowed to make a charge for distributing this Software, either for profit or merely to recover media and distribution costs.':
        'Vorbehaltlich der Bestimmungen dieses EULAs gewährt die Syntegra KG und Aux Code OOD dem Lizenznehmer hiermit eine unentgeltliche, widerrufliche, begrenzte, nicht-exklusive Lizenz für die Dauer dieses EULAs, eine Kopie der Software zu besitzen und zu benutzen. Die Software wird von Syntegra KG und Aux Code OOD vertrieben. Dem Lizenznehmer ist es nicht gestattet, für den Vertrieb dieser Software eine Gebühr zu erheben, weder mit Gewinnabsicht noch zur Deckung der Medien- und Vertriebskosten.',
      'Intellectual Property': 'Geistiges Eigentum',
      'You hereby unconditionally agree that all right, title and interest in the copyrights and other intellectual property rights in the Licensed Product reside with the Licensors. The trademarks, logos, designs, and service marks appearing on the Licensed Product are registered and unregistered marks of Licensor. Accordingly, nothing in this EULA or the Licensed Product grants you any right to use any form of intellectual property contained in the Licensed Product.':
        'Sie erklären sich hiermit vorbehaltlos damit einverstanden, dass alle Rechte, Titel und Interessen an den Urheberrechten und anderen geistigen Eigentumsrechten am Lizenzprodukt bei den Lizenzgebern liegen. Die Marken, Logos, Designs und Dienstleistungsmarken, die auf dem lizenzierten Produkt erscheinen, sind eingetragene und nicht eingetragene Marken des Lizenzgebers.  Dementsprechend gewähren Ihnen weder diese EULA noch das Lizenzierte Produkt das Recht, irgendeine Form von geistigem Eigentum, das im Lizenzierten Produkt enthalten ist, zu nutzen.',
      'Therefore, all rights, titles, interests, and copyrights in and/or to the Software, including but not limited to all images, graphics, animations, audio, video, music, text, data, code, algorithm, and information, are owned by the Licensor. Accordingly, the Software is protected by all applicable copyright laws and international treaties, and the Licensee is expected to use the Software concerning all intellectual property contained therein, except as otherwise provided for in this EULA.':
        'Daher sind alle Rechte, Titel, Interessen und Urheberrechte in und/oder an der Software, einschließlich, aber nicht beschränkt auf alle Bilder, Grafiken, Animationen, Audio-, Video-, Musik-, Text-, Daten-, Code-, Algorithmus- und Informationsinhalte, Eigentum des Lizenzgebers. Dementsprechend ist die Software durch alle anwendbaren Urheberrechtsgesetze und internationalen Verträge geschützt, und es wird vom Lizenznehmer erwartet, dass er die Software in Bezug auf das gesamte darin enthaltene geistige Eigentum nutzt, sofern in dieser EULA nichts anderes vorgesehen ist.',
      'Description of Rights and Limitations':
        'Beschreibung der Rechte und Beschränkungen',
      'Installation and Use:': 'Installation und Nutzung',
      " Licensee may install and use the Software on a shared computer or concurrently on different computers, and make multiple back-up copies of the Software, solely for Licensee's use within Licensee's business or personal use.":
        ' Der Lizenznehmer ist berechtigt, die Software auf einem gemeinsam genutzten Computer oder gleichzeitig auf verschiedenen Computern zu installieren und zu nutzen und mehrere Sicherungskopien der Software ausschließlich für den geschäftlichen oder privaten Gebrauch des Lizenznehmers zu erstellen.',
      'Reproduction and Distribution:': 'Vervielfältigung und Vertrieb:',
      ' Licensee may not duplicate or re-distribute copies of the Software, without the Licensors express written permission.':
        ' Der Lizenznehmer ist nicht berechtigt, Kopien der Software ohne die ausdrückliche schriftliche Genehmigung des Lizenzgebers zu vervielfältigen oder weiterzuverteilen. ',
      'Licensee Limitation:': 'Einschränkung des Lizenznehmers:',
      'The Licensee may not:': 'Der Lizenznehmer darf nicht:',
      'Use the Licensed Product for any purpose other than personal and non-commercial purposes;':
        'das lizenzierte Produkt für andere als persönliche und nicht-kommerzielle Zwecke zu verwenden;',
      'Use the Licensed Product for any illegal or unlawful purpose;':
        'das lizenzierte Produkt für illegale oder ungesetzliche Zwecke zu verwenden;',
      'Gather factual content or any other portion of the Licensed product by any automated means, including but not limited to database scraping or screen scraping; or':
        'das Sammeln von faktischen Inhalten oder anderen Teilen des lizenzierten Produkts durch automatisierte Mittel, einschließlich, aber nicht beschränkt auf Datenbank-Scraping oder Screen-Scraping; oder',
      'Reverse engineer, decompile, or disassemble Software, except and only to the extent that such activity is expressly permitted by applicable law notwithstanding the limitation.':
        'Software zurückzuentwickeln, zu dekompilieren oder zu disassemblieren, es sei denn, eine solche Tätigkeit ist ungeachtet dieser Einschränkung nach geltendem Recht ausdrücklich zulässig.',
      'Update and Maintenance': 'Aktualisierung und Wartung',
      'Syntegra KG and Aux Code OOD shall provide updates and maintenance on a monthly basis or as-needed basis.':
        'Syntegra KG und Aux Code OOD bieten monatliche oder bedarfsabhängige Aktualisierungen und Wartung an.',
      Support: 'Unterstützung',
      'Syntegra KG and Aux Code OOD has no obligation to Software support, or to continue providing or updating any of the Software.':
        'Syntegra KG und Aux Code OOD sind nicht verpflichtet, Software-Support zu leisten oder die Software weiterhin bereitzustellen oder zu aktualisieren.',
      Termination: 'Terminierung',
      'In the event of termination, all licenses provided under this EULA shall immediately terminate, and you agree to discontinue accessing or attempting to access this Licensed product.':
        'Im Falle einer Kündigung erlöschen alle unter dieser EULA gewährten Lizenzen sofort, und Sie erklären sich damit einverstanden, den Zugriff auf dieses lizenzierte Produkt oder den Versuch eines solchen Zugriffs einzustellen.',
      'Accordingly, this EULA may be:': 'Dementsprechend kann diese EULA sein:',
      'Automatically terminated if the Licensee fails to comply with any of the terms and conditions under this EULA;':
        'Automatisch gekündigt, wenn der Lizenznehmer eine der Bedingungen dieses EULAs nicht einhält;',
      'Terminated by Syntegra KG and Aux Code OOD; or':
        'Beendet durch Syntegra KG und Aux Code OOD; oder',
      'Terminated by the Licensee.': 'Beendigung durch den Lizenznehmer.',
      'Either Syntegra KG and Aux Code OOD or the Licensee may terminate this EULA immediately upon written notice to the other party, including but not limited to electronic mail.':
        'Sowohl Syntegra KG and Aux Code OOD als auch der Lizenznehmer können diesen EULA mit sofortiger Wirkung durch schriftliche Mitteilung an die jeweils andere Partei kündigen, einschließlich, aber nicht beschränkt auf elektronische Post.',
      'Non-Transferability': 'Nicht-Übertragbarkeit',
      'The Licensee has the option to permanently transfer all rights under this Agreement, provided the recipient agrees to the terms of this EULA. Accordingly, this EULA is not assignable or transferable by the Licensee without the prior written consent of Syntegra KG and Aux Code OOD; and any attempt to do so shall be void.':
        'Der Lizenznehmer hat die Möglichkeit, alle Rechte aus diesem Vertrag dauerhaft zu übertragen, sofern der Empfänger den Bedingungen dieses EULA zustimmt. Dementsprechend ist diese EULA ohne die vorherige schriftliche Zustimmung von Syntegra KG und Aux Code OOD nicht abtretbar oder übertragbar und jeder Versuch, dies zu tun, ist nichtig.',
      Notice: 'Hinweis',
      'Any notice, report, approval or consent required under this EULA shall be in writing and deemed to have been duly given if delivered by recorded delivery to the respective addresses of the parties.':
        'Alle Mitteilungen, Berichte, Genehmigungen oder Zustimmungen, die im Rahmen dieses EULA erforderlich sind, bedürfen der Schriftform und gelten als ordnungsgemäß erteilt, wenn sie per Einschreiben an die jeweiligen Adressen der Parteien zugestellt werden.',
      'Both parties hereby agree that this EULA is the entire and exclusive statement and legal acknowledgement of the mutual understanding of the parties and supersedes and cancels any previous written and oral agreement and/or communication relating to the subject matter of this EULA.':
        'Beide Parteien vereinbaren hiermit, dass diese EULA die vollständige und ausschließliche Erklärung und rechtliche Anerkennung des gegenseitigen Verständnisses der Parteien darstellt und alle früheren schriftlichen und mündlichen Vereinbarungen und/oder Mitteilungen in Bezug auf den Gegenstand dieser EULA ersetzt und aufhebt.',
      Severability: 'Trennbarkeit',
      'No delay or failure to exercise, on the part of either party, any privilege, power or rights under this EULA shall operate as a waiver of any of the terms and provisions of this EULA. Accordingly, no single or partial exercise of any right under this Agreement shall preclude further exercise of any other right under this EULA. Suppose any of the outlined provisions of this EULA is deemed to be unenforceable or invalid in whole or in part by a court of competent jurisdiction. In that case, such provision shall be limited to the minimum extent necessary for this EULA to remain in full force and effect and enforceable. The remaining provisions of this Agreement shall not be rendered unenforceable or invalid. They shall continue to be enforceable and valid in isolation of the unenforceable and invalid provisions of this EULA.':
        'Die verspätete oder unterlassene Ausübung von Privilegien, Befugnissen oder Rechten im Rahmen dieser EULA durch eine der Parteien gilt nicht als Verzicht auf eine der Bedingungen und Bestimmungen dieser EULA. Dementsprechend schließt die einmalige oder teilweise Ausübung eines Rechts aus dieser Vereinbarung die weitere Ausübung anderer Rechte aus dieser EULA nicht aus. Angenommen, eine der skizzierten Bestimmungen dieser EULA wird von einem zuständigen Gericht als nicht durchsetzbar oder ganz oder teilweise ungültig erachtet. In diesem Fall wird eine solche Bestimmung auf das Mindestmaß beschränkt, das erforderlich ist, damit diese EULA in vollem Umfang in Kraft bleibt und durchsetzbar ist. Die übrigen Bestimmungen dieser Vereinbarung werden dadurch nicht undurchsetzbar oder ungültig. Sie bleiben unabhängig von den nicht durchsetzbaren und ungültigen Bestimmungen dieser EULA durchsetzbar und gültig.',
      'Warranty and Disclaimer': 'Garantie und Haftungsausschluss',
      'Syntegra KG and Aux Code OOD, and author of this Software, expressly disclaim any warranty for the Qck.app. The Licensed Product and all applicable documentation is provided as-is, without warranty of any kind, whether express or implied, including, without limitation, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. Accordingly, the Licensee accepts any risk arising out of the use or performance of the Software.':
        'Syntegra KG und Aux Code OOD, sowie der Autor dieser Software, lehnen ausdrücklich jegliche Gewährleistung für die Qck.app ab. Das lizenzierte Produkt und die dazugehörige Dokumentation werden ohne jegliche ausdrückliche oder stillschweigende Gewährleistung zur Verfügung gestellt, einschließlich, aber nicht beschränkt auf die stillschweigende Gewährleistung der Marktgängigkeit, der Eignung für einen bestimmten Zweck oder der Nichtverletzung von Rechten Dritter. Dementsprechend übernimmt der Lizenznehmer jedes Risiko, das sich aus der Nutzung oder Leistung der Software ergibt.',
      'Limited Liability': 'Beschränkte Haftung',
      'The Licensee agrees that the Syntegra KG and Aux Code OOD shall not be liable to Licensee, or any other related person or entity claiming any loss of profits, income, savings, or any other consequential, incidental, special, punitive, direct or indirect damage, whether arising in contract, tort, warranty, or otherwise. Even if Syntegra KG and Aux Code OOD has been advised of the possibility of such damages. These limitations shall necessarily apply regardless of the primary purpose of any limited remedy. Under no circumstances shall Syntegra KG and Aux Code OOD aggregate liability to the Licensee, or any other person or entity claiming through the Licensee, exceed the actual monetary amount paid by the Licensee to Syntegra KreG and Aux Code OOD for the Software.':
        'Der Lizenznehmer erklärt sich damit einverstanden, dass die Syntegra KG und Aux Code OOD weder gegenüber dem Lizenznehmer noch gegenüber einer anderen verbundenen Person oder Einrichtung haftbar gemacht werden können, die Ansprüche auf entgangenen Gewinn, Einkommen, Einsparungen oder andere Folgeschäden, zufällige, besondere, strafende, direkte oder indirekte Schäden geltend machen, unabhängig davon, ob diese aus einem Vertrag, einer unerlaubten Handlung, einer Garantie oder anderweitig entstehen. Selbst wenn Syntegra KG und Aux Code OOD auf die Möglichkeit solcher Schäden hingewiesen wurde. Diese Beschränkungen gelten unabhängig vom Hauptzweck eines beschränkten Rechtsmittels. Unter keinen Umständen übersteigt die Gesamthaftung von Syntegra KG und Aux Code OOD gegenüber dem Lizenznehmer oder einer anderen natürlichen oder juristischen Person, die durch den Lizenznehmer Ansprüche geltend macht, den tatsächlichen Geldbetrag, den der Lizenznehmer an Syntegra KG und Aux Code OOD für die Software bezahlt hat.',
      Indemnification: 'Entschädigung',
      'You hereby agree to indemnify and hold Syntegra KG and Aux Code OOD harmless from and against all liabilities, damages, losses or expenses, including but not limited to reasonable attorney or other professional fees in any claim, demand, action or proceeding initiated by any third-party against Syntegra KG and Aux Code OOD, arising from any of your acts, including without limitation, violating this EULA or any other agreement or any applicable law.':
        'Sie verpflichten sich hiermit, Syntegra KG und Aux Code OOD von allen Verbindlichkeiten, Schäden, Verlusten oder Ausgaben freizustellen und schadlos zu halten, einschließlich, aber nicht beschränkt auf angemessene Anwalts- oder andere professionelle Gebühren in allen Ansprüchen, Forderungen, Klagen oder Verfahren, die von Dritten gegen Syntegra KG und Aux Code OOD eingeleitet werden und die sich aus einer Ihrer Handlungen ergeben, einschließlich, aber nicht beschränkt auf die Verletzung dieser EULA oder einer anderen Vereinbarung oder eines geltenden Gesetzes.',
      'Entire Agreement': 'Gesamte Vereinbarung',
      'This Agreement rightly constitutes the entire understanding between the Syntegra KG and Aux Code OOD and the Licensee and all parties involved. It supersedes all prior agreements of the parties, whether written or oral, express or implied, statement, condition, or a representation or warranty.':
        'Diese Vereinbarung stellt zu Recht die gesamte Vereinbarung zwischen der Syntegra KG und Aux Code OOD und dem Lizenznehmer und allen beteiligten Parteien dar. Sie ersetzt alle früheren Vereinbarungen der Parteien, ob schriftlich oder mündlich, ausdrücklich oder stillschweigend, als Erklärung, Bedingung oder als Zusicherung oder Garantie.',
      'Governing Law and Jurisdiction': 'Geltendes Recht und Gerichtsstand',
      'It is agreed, that all legal actions will be treated under Austrian law before an Austrian Court.':
        'Es wird ausschliesslich österreichisches Recht sowie als Gerichtsstandort das jew. Zuständige österreichische Gericht vereinbart.',
      'Contact us': 'Kontakt',
      'Contact Us': 'Kontakt',
      'For questions regarding professional or commercial use of this app please contact:':
        'Für Fragen zu Anwendung und betrieblicher Nutzung schreiben Sie bitte an: ',
      'Results have not been released by the owner yet.':
        'Die Ergebnisse wurden noch nicht freigeschaltet.',
      'For technical questions or issues please contact:':
        'Bei technischen Fragen wenden Sie sich bitte an:',
      'Allow voters to see the results': 'Ergebnis für alle freischalten',
      'Give the meeting a name': 'Name des Meetings (Arbeitsfrage)',
      'The result takes us further': 'Das Ergebnis bringt uns weiter.',
      'We have discussed even controversial issues in an appreciative way. Everyone contributed.':
        'Wir haben auch kontroversielle Themen wertschätzend diskutiert. Alle haben sich eingebracht.',
      'I know exactly what I have to do. I deliver my contribution at the agreed time in the agreed quality.':
        'Ich weiß, was ich zu tun habe. Ich liefere meinen Beitrag zum vereinbarten Zeitpunkt in der vereinbarten Qualität.',
      'I deliver my contribution at the agreed time in the agreed quality.':
        'Ich liefere meinen Beitrag zum vereinbarten Zeitpunkt in der vereinbarten Qualität.',
      'What else is needed at this stage? What might we have missed?':
        'Was braucht es zum jetzigen Zeitpunkt noch? Was haben wir eventuell übersehen?',
      'What else do I need to deliver my contribution reliably?':
        'Was brauche ich noch, um meinen Beitrag zuverlässig zu liefern?',
      ' if you just had a meeting and in just 2 minutes want to achieve:':
        ', wenn Sie ein Meeting hatten und mit nur 2 Minuten Aufwand folgendes möchten:',
      'Improved results': 'Verbesserte Ergebnisse',
      'Continuous increase in efficiency':
        'Kontinuierliche Steigerung der Effizienz',
      'Strengthening the team culture': 'Stärken der Teamkultur',
      'More commitment to implementation':
        'Mehr Commitment für die Umsetzung',
      ' if you are currently managing a transformation or change and want to ensure the most important thing - communication in the company regarding the change:':
        ', wenn Sie gerade eine Transformation oder einen Change managen und das Wichtigste - die Kommunikation im Unternehmen zum Change -  sicher stellen wollen:',
      'Invite your team members to answer the questions, it only takes 2 minutes':
        'Laden Sie Ihre Teammitglieder ein, die Fragen zu beantworten, es dauert nur 2 Minuten',
      'Start a dialogue that will help you to manage the change together with your team more successfully':
        'Starten Sie einen Dialog, der Sie dabei unterstützt, den Change mit dem Team erfolgreicher zu managen',
      ' if together with your team you want to strengthen cooperation and sustainably increase impact:':
        ', wenn Sie gemeinsam mit Ihrem Team die Zusammenarbeit stärken und die Schlagkraft nachhaltig stärken wollen:',
      'Start a dialogue to strengthen and further develop the team success factors':
        'Starten Sie einen Dialog, um die Team-Erfolgsfaktoren zu stärken und weiter zu entwickeln',
      ' if you want to get more momentum in your organisation by following 4 simple questions:':
        ', wenn Sie anhand von 4 einfachen Fragen mehr Schwung in Ihre Organisation bringen wollen:',
      'Start a dialogue to define measures that strengthen commitment, result orientation and fun at work':
        'Starten Sie einen Dialog, um Maßnahmen zu definieren, die Verbindlichkeit, Ergebnisorientierung und Spaß an der Arbeit stärken',
      'Click ': 'Klicken Sie ',
      here: 'hier',
      "This question doesn't apply for me": 'Trifft nicht zu',
      "Invitation received?": "Einladung erhalten?",
      "insert code": "Code hier eingeben",
      'Increase team effectiveness with smart surveys!': 'Mehr Wirksamkeit im Team mit schnellen Umfragen!',
      'Support consequent development of your team through regular, structured feedback!': 'Unterstützen Sie die konsequente Entwicklung Ihres Teams durch regelmässiges, strukturiertes Feedback!',
      'Start now!': 'Jetzt starten',
      'More info': 'Mehr erfahren',
      'Choose from 4 ways to develop your team and improve the efficiency to achieve better results in just 2 minutes!': 'Wählen Sie aus 4 Möglichkeiten, wie Sie Ihr Team stärken und die Performance steigern wollen!',
      'Improved meeting results': 'Verbesserte Meetingergebnisse',
      'Continuous increase in meeting efficiency': 'Kontinuierliche Steigerung der Effizienz in Meetings',
      'Strengthening the team culture': 'Stärken der Teamkultur',
      'More commitment to implementation': 'Mehr Commitment für die Umsetzung',
      'Strengthen your team cooperation in the long term': 'Stärken Sie nachhaltig Ihre Team-Zusammenarbeit',
      'Focus on what is relevant and helps the team to increase impact': 'Erhöhen Sie Ihre Schlagkraft',
      'Start a dialogue to strengthen and develop the team success factors': 'Starten Sie einen Dialog, um die Team-Erfolgsfaktoren zu stärken und weiterzuentwickeln',
      'Bring more momentum into your organization': 'Bringen Sie mehr Schwung in Ihre Organisation',
      'Challenge commitment, result-orientation and fun at work. Start a dialogue to strengthen and develop the impact of your mission': 'Schaffen Sie Maßnahmen, die Verbindlichkeit, Ergebnis-orientierung und Spaß an der Arbeit stärken',
      'Manage your change with impact by ensuring clear communication.': 'Managen Sie Ihren Change effektiver und sorgen Sie für eine klare Kommunikation',
      'Create a trusting environment where the whole team has its say Start a dialogue to support your change-impact!': 'Schaffen Sie eine vertrauensvolle Umgebung, in der das gesamte Team zu Wort kommt',
      'What is the working question* of this meeting?': 'Wie lautet die Arbeitsfrage* dieses Meetings?',
      'Ensure improved results and a continuous increase in efficiency in your meetings!': 'Sorgen Sie für wirksamere Ergebnisse und eine kontinuierliche Steigerung der Effizienz in Ihren Meetings!',
      'TIME SPENT IN MEETING (MIN.)': 'DAUER DES MEETINGS (MINUTEN)',
      'Start Meeting Follow-Up': 'Meeting Follow-Up starten',
      'Start Team Check-In': 'Team Check-In starten',
      'Start Mission Statement Check': 'Mission Statement Check starten',
      'Start Change Communication Survey': 'Change Communication Survey starten',
      'Boost the impact and cohesion of your team for sustainable success!': 'Stärken Sie nachhaltig die Schlagkraft und den Zusammenhallt in Ihrem Team!',
      'Bring more momentum into your organisation!': 'Bringen Sie mehr Schwung in Ihre Organisation!',
      'Support your change with impactful communication!': 'Unterstützen Sie Ihren Change mit wirksamer Kommunikation!',
      'Send out the invitation to answer the questions before your team meeting in order to be able to derive targeted strengthening measures on the basis of the answers.': 'Senden Sie die Einladung zur Beantwortung vor Ihrem Team-Meeting aus, um auf Basis der Antworten zielgerichtet stärkende Massnahmen ableiten zu können.',
      'Answering the questions only takes a few minutes, reserve about an hour for the meeting afterwards.': 'Die Beantwortung der Fragen dauert nur wenige Minuten, reservieren Sie für das Meeting im Anschluss ca eine Stunde.',
      'Due to the quick response, you can also start the survey in the team meeting itself.': 'Sie können aufgrund der schnellen Beantwortung die Befragung auch im Team-Meeting selbst starten!',
      'With only 4 questions, you can check essential success factors for companies, based on a proven action model.': 'Mit nur 4 Fragen überprüfen Sie wesentliche Erfolgsfaktoren für Unternehmen, Basis ist ein bewährtes Handlungsleitbild.',
      'Answering the questions takes only a few minutes, reserve about an hour for the meeting afterwards.': 'Die Beantwortung der Fragen dauert nur wenige Minuten, reservieren Sie für das Meeting im Anschluss ca eine Stunde.',
      'You can also start the survey in the team meeting itself because of the quick answers!': 'Sie können aufgrund der schnellen Beantwortung die Befragung auch im Team-Meeting selbst starten!',
      'Send out the invitation to answer the questions before your team meeting so that you can derive targeted supportive measures for your change communication.': 'Senden Sie die Einladung zur Beantwortung vor Ihrem Team-Meeting aus, um zielgerichtet unterstützende Maßnahmen für Ihre Change-Kommunikation ableiten zu können.',
      'Answering the questions takes only a few minutes; reserve about an hour for the meeting itself.': 'Die Beantwortung der Fragen dauert nur wenige Minuten, reservieren Sie für das Meeting selbst ca eine Stunde.',
      'Due to the quick response, you can also start the survey in the team meeting itself!': 'Sie können aufgrund der schnellen Beantwortung die Befragung auch im Team-Meeting selbst starten!',
      'How to increase your team performance simply and effectively with qck.app': 'Wie Sie mit qck.app einfach und wirksam Ihre Team-Performance steigern',
      'Learn how qck.app works in an interactive video demo, or sign up for a free trial of qck.app.': 'Erfahren Sie in einer interaktiven Video-Demo wie Qck funktioniert oder melden Sie sich für eine kostenlose Demo',
      'We are looking forward to answer your questions!': 'Jetzt Kontakt aufnehmen',
      'Please leave your data now and we will get in touch with you as soon as possilbe!': 'Hinterlassen Sie Ihre Kontaktdaten und wir melden uns persönlich bei Ihnen.',
      'Your name*': 'Name*',
      'Your email contact*':'Unternehmens E-Mailadresse*',
      'What would you like to achieve with your team?':'Wie viele Team-Meetings pro Woche?',
      'How many people would you like to involve?':'Wie groß ist Ihr Team?',
      'Get in contact with us!':'Jetzt Kontakt aufnehmen',
      'Continue':'Weiter',
      'Enter the task of the Meeting right here in the form of a working question*':'Tragen Sie hier ein, welche zentrale Frage in diesem Meeting beantwortet werden soll',
      'Enter the name of the team for which you would like to start the check here':'Tragen Sie hier den Namen des Teams ein, für welches Sie den Check-In starten möchten',
      'Enter the name of the organisation for which you would like to start the check here': 'Tragen Sie hier den Namen der Organisation ein, für welches Sie den Check-In starten möchten',
      '* If you want to know more about working questions, click ': '*Wenn Sie mehr zu Arbeitsfragen wissen wollen, klicken Sie ',
      'here': 'hier',
      'How would you like to improve your team performance today?': 'Wie wollen Sie Ihre Team-Performance heute stärken?',
      "Welcome to Qck app!": "Willkommen bei Qck app!",
      "Here's a quick introduction to get started. ": "Hier ist eine kurze Einführung für den Einstieg.",
      "Add Qck to a meeting": "Fügen Sie Qck einem Meeting hinzu",
      'Join a scheduled meeting and find the "App" button, as shown in the image below: ': "Nehmen Sie an einer geplanten Besprechung teil und suchen Sie die Schaltfläche 'App', wie auf dem Bild unten gezeigt:",
      "When you click this button a page listing all your available apps should pop up. ": "Wenn Sie auf diese Schaltfläche klicken, sollte eine Seite erscheinen, auf der alle Ihre verfügbaren Apps aufgelistet sind.",
      "From this page navigate to the Qck App icon and click it. ": "Navigieren Sie auf dieser Seite zum Qck-App-Symbol und klicken Sie darauf.",
      "The introduction page for the app should pop up. There you can read about our EULA and contact us, as well as learn more about Qck": "Die Einführungsseite für die App sollte sich öffnen. Dort können Sie unsere EULA lesen, uns kontaktieren und mehr über Qck erfahren.",
      'Click the "Save" button and Qck should appear in the side panel of your Teams client, ready to use! ': 'Klicken Sie auf die Schaltfläche "Speichern", und Qck sollte in der Seitenleiste Ihres Teams-Clients erscheinen und einsatzbereit sein!',
      "A link to access the questionnaire has been sent to everyone in the meeting.": "Ein Link zum Zugriff auf den Fragebogen wurde an alle Teilnehmer der Besprechung gesendet.",
      "Show results": "Ergebnisse anzeigen",
      "Start a new questionnaire?": "Einen neuen Fragebogen starten?",
      "New questionnaire": "Neuer Fragebogen",
      "Want a different questionnaire?": "Wollen Sie einen anderen Fragebogen?",
      "Start a Meeting Follow-Up?": "Ein Meeting-Follow-up starten?",
      "Click here!": "Klicken Sie hier!",
      "With Qck App you can support consequent development of your team through regular, structured feedback! This is done by creating quick and easy questionnaires in your Microsoft Teams meetings, that everyone in your meeting can answer almost instantly.": "Mit Qck App können Sie die konsequente Weiterentwicklung Ihres Teams durch regelmäßiges, strukturiertes Feedback unterstützen! Dies geschieht durch die schnelle und einfache Erstellung von Fragebögen in Ihren Microsoft Teams Meetings, die jeder in Ihrem Meeting fast sofort beantworten kann.",
      "Choose from 4 types of questionnaires, designed for different feedback from your teammates.": "Wählen Sie aus 4 Arten von Fragebögen, die für unterschiedliches Feedback Ihrer Teammitglieder konzipiert sind.",
      "Start questionnaires with the click on a button.": "Starten Sie Fragebögen mit einem Klick auf die gewünschte Schaltfläche.",
      "See the results instantly on our site.": "Sehen Sie die Ergebnisse sofort auf unserer Website.",
      "Answer questionnaires as soon as your organiser creates them.": "Beantworten Sie Fragebögen, sobald Ihr Organisator sie erstellt hat.",
      'Your feedback will be appreciated!': 'Ihr Feedback wird geschätzt!',
      'Answer with a number between 0 and 10, depending on how much you agree with the statement': 'Antworten Sie mit einer Zahl zwischen 0 und 10, je nachdem, wie sehr Sie der Aussage zustimmen',
      'I have a clear picture of what should be different and better after the change than before.': 'Ich habe ein klares Bild davon, was nach dem Change anders und besser sein soll als davor.',
      'What questions are open for me?': 'Welche Fragen sind für mich offen?',
      'It is easy for me to convince others of the necessity of change.': 'Es fällt mir leicht, andere von der Notwendigkeit des Change zu überzeugen.',
      'What arguments would help me to convince others?': 'Welche Argumente würden mir helfen, um andere zu überzeugen?',
      'We take enough time to talk about new rules of cooperation and how they serve the change objective.': 'Wir nehmen uns ausreichend Zeit, um über neue Regeln der Zusammenarbeit zu sprechen und wie sie dem Change-Ziel dienen.',
      'What specifically should we change?': 'Was konkret und in welchem Umfang sollten wir ändern?',
      'When something is communicated with us, I can trust that it is correct. Even official announcements turn out to be true.': 'Wenn etwas bei uns kommuniziert wird, kann ich darauf vertrauen, dass es richtig ist. Auch offizielle Ankündigungen werden eingehalten.',
      'What do I need specifically to be able to trust official information, especially during changes?': 'Was brauche ich konkret, um gerade im Change offiziellen Informationen vertrauen zu können?',
      'I can speak openly if something in the change does not go as planned.': 'Die meisten sprechen auch vor den Verantwortlichen offen über das, was nicht so gut läuft.',
      'What is generally missing in our culture to support more openness?': 'Was fehlt in unserer Kultur generell, um mehr Offenheit zu fördern?',
      'I have a clear picture of what should be different and better after the change than before.': 'Ich habe ein klares Bild davon, was nach dem Change anders und besser sein soll als davor.',
      'What questions are open for me?': 'Welche Fragen sind für mich offen?',
      'It is easy for me to convince others of the necessity of change.': 'Es fällt mir leicht, andere von der Notwendigkeit des Change zu überzeugen.',
      'What arguments would help me to convince others?': 'Welche Argumente würden mir helfen, um andere zu überzeugen?',
      'We take enough time to talk about new rules of cooperation and how they serve the change objective.': 'Wir nehmen uns ausreichend Zeit, um über neue Regeln der Zusammenarbeit zu sprechen und wie sie dem Change-Ziel dienen.',
      'What specifically should we change?': 'Was konkret und in welchem Umfang sollten wir ändern?',
      'When something is communicated with us, I can trust that it is correct. Even official announcements turn out to be true.': 'Wenn etwas bei uns kommuniziert wird, kann ich darauf vertrauen, dass es richtig ist. Auch offizielle Ankündigungen werden eingehalten.',
      'What do I need specifically to be able to trust official information, especially during changes?': 'Was brauche ich konkret, um gerade im Change offiziellen Informationen vertrauen zu können?',
      'I can speak openly if something in the change does not go as planned.': 'Die meisten sprechen auch vor den Verantwortlichen offen über das, was nicht so gut läuft.',
      'What is generally missing in our culture to support more openness?': 'Was fehlt in unserer Kultur generell, um mehr Offenheit zu fördern?',
      'I know the goals for our team and what we are working for.': 'Ich kenne die Ziele für unser Team und weiss, wofür wir uns einsetzen',
      'What information am I missing?': 'Welche Informationen fehlen mir?',
      'My role and responsibilities in the team are clear to me, I feel up to my tasks.': 'Meine Rolle und meine Verantwortung im Team sind mir klar, ich fühle mich meinen Aufgaben gewachsen.',
      'What else do I need to fulfil my role?': 'Was brauche ich noch, um meine Rolle zu erfüllen?',
      'When problems arise, we have a common understanding of how to solve them quickly and effectively.': 'Wenn Probleme auftauchen, haben wir ein gemeinsames Verständnis, wie wir diese schnell und wirksam lösen.',
      'What should we change to become more efficient?': 'Was sollten wir ändern, um effizienter zu werden?',
      'We resolve conflicts promptly and with the involvement of those affected.': 'Konflikte werden bei uns zeitnah und unter Einbindung der Betroffenen gelöst.',
      'What should we change to deal with conflicts more constructively?': 'Was sollten wir ändern, um mit Konflikten konstruktiver umzugehen?',
      'We have common values that we can orientate ourselves by and that unite us. I can name at least two.': 'Wir haben gemeinsame Werte, an denen wir uns orientieren können und die uns verbinden. Ich kann mindestens zwei nennen.',
      'Which values should we strengthen? How?': 'Welche Werte sollten wir stärken? Wie?',
      'When there is a need for change, we respond quickly as a team and adapt to the new demands.': 'Wenn es die Notwendigkeit für Änderungen gibt, reagieren wir als Team rasch darauf und stellen uns auf die neuen Anforderungen ein.',
      'What do we need to respond even more quickly to new demands?': 'Was brauchen wir, um noch rascher auf neue Anforderungen reagieren zu können?',
      'We regularly take time to work out in a structured way how we can strengthen our performance as a team and our cohesion.': 'Wir nehmen uns regelmäßig Zeit, um strukturiert zu erarbeiten, wie wir unsere Performance als Team und unseren Zusammenhalt stärken können.',
      'What should we do to get into a regular development routine?': 'Was sollten wir tun, um in eine regelmäßige Entwicklungsroutine zu kommen?',
      'We are getting down to business!': 'Bei uns wird angepackt!',
      'How could we motivate more proactivity?': 'Wie könnten wir zu mehr Proaktivität motivieren?',
      'Tasks are finished in a way, that those who continue to work with the results, can do that efficiently.': 'Begonnene Arbeit wird zu Ende gebracht, damit die Leistungsempfänger mit den Ergebnissen gut weiter arbeiten können.',
      'What should we do to improve the quality of our results?': 'Was sollten wir tun, um die Qualität unserer Ergebnisse zu verbessern?',
      'Commitments are kept.': 'Zusagen werden eingehalten.',
      'How can we improve reliability in the organisation?': 'Wie können wir die Zuverlässigkeit in der Organisation verbessern?',
      'Even when things get tough, we don\'t lose the fun in what we do.': 'Selbst wenn es härter hergeht, verlieren wir nicht den Spaß an dem, was wir tun.',
      'What would help me find more joy in my work?': 'Was würde mir helfen, mehr Freude bei meiner Arbeit zu finden?',
      'I receive all contributions within our team at the agreed time and in the agreed quality.': 'Ich erhalte innerhalb unseres Teams alle Leistungen zum vereinbarten Zeitpunkt und in vereinbarter Qualität.',
      'Where specifically should we as a team focus on reliability?': 'Wo sollten wir als Team unsere Zuverlässigkeit steigern?'





    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('language') || 'de', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng optio

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
