import * as React from 'react';
import style from './Circle.module.css';

export default function Circle(props: {
  letter: string
  color: string
  yOffset: number
  style?: any
}){
  return (
    <span 
      style={{
        backgroundColor: props.color,
        transform: `translateY(${-props.yOffset * 5}px)`,
        ...props.style
      }} 
      className={style.circle}
    >
      {props.letter}
    </span>
  )
}
