import React, {useState} from 'react';
import style from './EnterCode.module.css';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { ReactComponent as IconRightArrow } from '../../images/Icon-arrow-down.svg';

export default function EnterCode(props: {
  submit: (codeToSearch: string) => void
}) {

  const [ code, setCode ] = useState<string>("");

  const handleInsertCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inField = event.target;

    if(!event.target) return;

    setCode(inField.value);
    console.log(inField.value);
  }

  const { t } = useTranslation();
  return (
    <div
      className={style.enterCodeDiv}
    >
      <a
        className= {style.inviteRecievedCapt}
      >
        {t('Invitation received?')}
      </a>
      <div className={style.idField}>
        <a style={{ color: '#016fa0', fontSize: '30px' }}>#</a>
        <input
          type="text"
          id="qnrId"
          className={style.qnrId}
          name="qnrId"
          placeholder={t('insert code') || ''}
          value={code}
          onChange={handleInsertCodeChange}
        ></input>
        <Button className={style.searchBtn} onClick={() => props.submit(code)}>
          <IconRightArrow />
        </Button>
      </div>
    </div>
  );
}
