import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import style from './ChooseType.module.css';
import Navbar from '../../Navbar';
import { Fieldset } from 'primereact/fieldset';
import { NUMBER_TYPE_TYPE_MAP } from '../../constants';
import { TYPE_POINTS_MAP } from '../../constants';
import UserAgreementLink from '../../components/UserAgreementLink';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import TypeIcon from '../../components/TypeIcon';
import axios from 'axios';
import { global } from '../../types/global';

import './ChooseType.css';
import { create } from 'domain';
import FullScreenLoadingOverlay from '../../components/FullScreenLoadingOverlay';
import EnterCode from '../../components/EnterCode/EnterCode';

const QCK_VER = process.env.REACT_APP_QCK_VER;

export default function ChooseType() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [ loading, setLoading ] = React.useState<boolean>(false);

  const searchMeeting = (codeToSearch: string) => {
    console.log('trying to search', codeToSearch);
    if(codeToSearch.length !== 6){
      return;
    }

    axios
    .get('/api/search', { params: { id: codeToSearch } })
    .then((result) => {
      const { meeting, link }: { meeting: Meeting | QuickMeeting, link: string } = result.data;
      if(meeting){
        setLoading(false);
        window.setTimeout(() => navigate(link), 50);
      }else{
        alert(t('No questionnaire with this code was found! Try again.'));
        window.location.reload();
      }
    })
    .catch((err) => {
      console.log("[ERROR]:", err);
    })

    setLoading(true);
  }

  const createMeeting = (type: MeetingType) => {

    let meetingType: MeetingType = type; //!== 5 ? type : 1;
    console.log("version", QCK_VER);
    if(QCK_VER === '1'){
      meetingType = type === 1 ? 5 : type;
    }
    
    console.log("creating with type", meetingType);

    if(meetingType == 1){
      
      axios.post('/api/meeting-new', { meetingType: 1 })
      .then((result) => {
        const meetingId = result.data.id as QuickMeeting;
        if(meetingId){
          setLoading(false);
          window.setTimeout(() => navigate(`/${meetingId}/agenda`), 50);
        }
      })
      .catch((err) => {
        console.log("[ERROR][create_meeting, type:1]: ", err);
        setLoading(false);
        navigate('/login');
      })
      .finally(() => setLoading(false));

    }else{

      axios.post('/api/quick', { type: meetingType })
      .then((result) => {
        const meeting = result.data.meeting as QuickMeeting;
        if(meeting){
          setLoading(false);
          window.setTimeout(() => navigate(`/qck/${meeting.id}/agenda`, { state: { meeting } }), 50);
        }
      })
      .catch((error) => {
        console.log(`[ERROR][create_meeting, type:${meetingType}]: ${error}`);
        setLoading(false);
        window.location.href='/login'
      }).finally(() => {
        setLoading(false);
      })
    }
    setLoading(true);
  }
  return (
    <>
      <FullScreenLoadingOverlay show={loading} />
      <Navbar />
      <EnterCode submit={searchMeeting}/>
      <div style={{display:'flex',flexDirection:'column'}}>  
        <a  className={style.p1}  id = 'page-heading'>{t('How would you like to improve your team performance today?')}</a>
        <a className={style.p2}> {t('Choose from 4 ways to develop your team and improve the efficiency to achieve better results in just 2 minutes!',)}</a>
        <br />
        <div className={style.mainContent}>
          {Object.values(NUMBER_TYPE_TYPE_MAP).map((value, key) =>{
            const meetingType = (key+1) as unknown as MeetingType;
            return (
              <>
                { meetingType !== 5 &&
                <Fieldset legend={t(value)} className={style.fieldset} key={key}>
                  <div className={style.fieldsetcont}>
                    <TypeIcon type={meetingType} className={style.distributionCard} />
                    <ul className={style.lineCont}>
                      {TYPE_POINTS_MAP[meetingType].map((text,key) => (
                        <li key={key} className={style.line}>
                          {t(text)}
                        </li>
                      ))}
                    </ul>
                    <Button label={"Start " + t(NUMBER_TYPE_TYPE_MAP[meetingType])} className={style.startButton} raised p-ripple onClick={() => createMeeting(meetingType)}/>
                  </div>
                </Fieldset>
                }
              </>
            )})};
        </div>
        { QCK_VER === '2' &&
        <>
          <a className={style.p1}>{t('Or start a meeting now')}</a>
          <a className={style.p2} style={{marginTop:'-10px'}}>{t('Get straight to a questionnaire with Qck Now')}</a>
          <div className={style.qckCont}>
            <Button label="Qck Now" className={style.qckBtn} onClick={() => createMeeting(5)}></Button>
          </div>
        </>
        }
      </div>
      <div style={{ paddingTop: '20px' }}>
        <UserAgreementLink />
      </div>
    </>
  )
}

