import * as React from 'react';
import {Route, Routes, useParams, useLocation} from 'react-router-dom';
import Navbar from "../../../Navbar";
import UserAgreement from "../../../components/UserAgreementLink";
import Agenda from './Agenda';
import Results from './Results';

import style from './QuickRouter.module.css';
import axios from 'axios';
import Vote from './Vote';

export default function QuickRotuer() {

  const location = useLocation();

  const [ isLoggedInUserOwner, setIsLoggedInUserOwner ] = React.useState<boolean>();

  const userEmail = sessionStorage.getItem('qckEmail');
  const { id } = useParams();

  const [ meeting, setMeeting ] = React.useState<QuickMeeting | undefined>(location.state?.meeting || undefined);

  const changeWorkingQuestionText = async (newWorkingQuestion: string) => {
    setMeeting(curr => {
      if(!curr) return curr;

      return {...curr, workingQuestion: { ...curr.workingQuestion, text: newWorkingQuestion} }
    });

    axios.put('/api/quick', { id, queries: { setQuery: {
      workingQuestion: { ...meeting?.workingQuestion, text: newWorkingQuestion }
    }}})
    .then((response) => {
      //console.log(response.status);
      return;
    })
    .catch((error) => {
      console.log(error);
    })

  }

  const changeAreResultsPublic = async (newState: boolean) => {
    setMeeting(curr => {
      if(!curr) return curr;
      return { ...curr, areResultsPublic: newState };
    })

    axios.put('/api/quick', { id, queries: { setQuery: {
      areResultsPublic: newState
    }}})
    .then((response) => {
      return;
    })
    .catch((error) => {
      console.log(error);
    })
  }

  React.useEffect(() => {
    if(!meeting) return;

    setIsLoggedInUserOwner(meeting.ownerEmail === userEmail);
  }, [meeting]);

  React.useEffect(() => {
    if(meeting) return;

    axios.get(`/api/quick`, { params: { id } })
    .then((result) => {
      //console.log(`INFO: recieved meeting for id=${id}:\n${JSON.stringify(result.data)}`);
      setMeeting(result.data || {});
    })
    .catch(error => {
      console.log(error);
    });

    //console.log("[INFO][QuickRouter] Made request for meeting data");

  }, []);

  return (
    <section className={style.router}>
      <Navbar />
      <section className={style.content}>
        {meeting && isLoggedInUserOwner !== undefined ? 
          <Routes>
            <Route path='/agenda' element={<Agenda meeting={meeting} changeWorkingQuestion={changeWorkingQuestionText}/>} />
            <Route path='/results' element={<Results meeting={meeting} changeAreResultsPublic={changeAreResultsPublic} isOwner={isLoggedInUserOwner}/>} />
            <Route path='/vote' element={<Vote meeting={meeting} updateMeetingState={setMeeting} isLoggedInUserOwner={isLoggedInUserOwner} />} />
          </Routes>
        : 
          //TODO LOADING SCREEN
        <></>}
      </section>
      <UserAgreement />
    </section>
  )
}
