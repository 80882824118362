import React, {useEffect} from 'react';
import style from './FullScreenLoadingOverlay.module.css';

export default function FullScreenLoadingOverlay(props:{
  show: boolean;
}){

  useEffect(() => {
    if(props.show){
      document.body.classList.add('no-scroll');
    }else{
      document.body.classList.remove('no-scroll');
    }
  }, [props.show])

  return (
    <>
      { props.show && <div className={style.loadingOverlay}></div> }
    </>
  )
}
