import React, { createContext, useState, useEffect } from 'react';
import { authService } from '../authService';

type TAuthContext = {
  login: (email: string, language: string, saveLogin: boolean, afterLoginLocation?: string) => Promise<any>,
  confirm_login_otp: (email: string, saveLogin: boolean, otp: string) => Promise<any>,
  confirm_login_code: ( email: string, key: string, keepLoggedIn: boolean, teams?: boolean) => Promise<any>,
  logout: () => Promise<any>,
  user: string | null,
  loading: boolean,
}

export const AuthContext = createContext<TAuthContext | null>(null);

export const AuthProvider = ({ children }: any) => {

  const [user, setUser] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const [destination, _setDestination] = useState<string | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      const currentUser = await authService.getCurrentUser();
      if(currentUser){
        setUser(currentUser);
      }
      setLoading(false);
    };

    fetchUser();
  }, []);

  const setDestination = (dest: string): void => {
    if(!dest) return;
    console.log(`INFO: setting destination to ${dest}`);
    _setDestination(dest);
    localStorage.setItem('dest', dest);
  }

  const goToOriginalDestination = () => {
    if(!destination){
      console.log(`INFO: Navigating to ${localStorage.getItem('dest')}`);
      window.location.href = localStorage.getItem('dest') || '/'
    }else{
      console.log(`INFO: Navigating to ${destination}`);
      window.location.href = destination || '/'
    }
  }

  const login = async(email: string, language: string, saveLogin: boolean, afterLoginLocation?: string): Promise<any> => {
    setDestination(afterLoginLocation || '/');
    authService.login(email, language, saveLogin)
    .then(async(result) => {
      if(result){
        setUser(await authService.getCurrentUser());
      }
    })
  }
  const confirm_login_otp = async(email: string, saveLogin: boolean, otp: string) => {
    authService.confirm_login_otp(email, saveLogin, otp)
    .then(async(result) => {
      console.log(`INFO: AuthContext: OTP login result->${result}`);
      console.log(`INFO: AuthContext: OTOP login: going to original destination: ${destination}`);
      setUser(await authService.getCurrentUser());
      goToOriginalDestination();
    })
  }
  const confirm_login_code = async( email: string, key: string, keepLoggedIn: boolean, teams?: boolean) => {
    authService.confirm_login_code(email, key, keepLoggedIn, teams)
    .then(async(result) => {
      console.log(`INFO: AuthContext: Code login result->${result}`);
      console.log(`INFO: AuthContext: Code login: going to original destination: ${destination}`);
      setUser(await authService.getCurrentUser());
      goToOriginalDestination();
    }).catch((err) => console.log(`INFO: User not logged in`));
  }
  const logout = async() => {
    await authService.logout()
    .catch((err) => console.log(`ERROR: AuthContext: logout: couldnt log out->${err}`));
    setUser(null);
    window.location.href = '/';
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading, confirm_login_code, confirm_login_otp }}>
      {children}
    </AuthContext.Provider>
  );
};
