import axios from 'axios';
import { getUserSelectedLanguage } from './functions/getUserLanguage';

export type TLoginFunction = (email: string, language: string, saveLogin: boolean) => Promise<any>;
export type TConfirmLoginOPTFunction = (email: string, saveLogin: boolean, otp: string) => Promise<any>;
export type TConfirmLoginCodeFunction = ( email: string, key: string, keepLoggedIn: boolean, teams?: boolean) => Promise<any>;
export type TLogoutFunction = () => Promise<any>;
export type TGetCurrentUserFunction = () => Promise<string | null>;

export const authService = {
  login: ((email: string, language: string, saveLogin: boolean) => {
    console.log(`INFO: Request to log in with ${email}`);
    const request = axios
    .get('/api/signin', {
      params: { 
        email: email,
        language: getUserSelectedLanguage(),
        saveLogin
      },
    }).then((result) => {
      console.log(`INFO: Login OK`);
      return;
    }).catch((err) => {
      console.log(`ERROR: Login error->${err}`);
      return;
    })
    return request;
  }) as TLoginFunction,
  confirm_login_otp: ((email: string, saveLogin: boolean, otp: string) => {
    console.log(`INFO: Trying to confirm login ${email} ${otp}`);
    const request = axios
    .get('/api/otp-login', { params: {
      keepLoggedIn: saveLogin, otp, email
    }}).then((result) => {
      sessionStorage.setItem('qckEmail', email);
      console.log(`INFO: Login OK`);
    }).catch((err) => {
      console.log(`ERROR: Login error->${err}`);
      return;
    })
    return request;
  }) as TConfirmLoginOPTFunction,

  confirm_login_code: (( email: string, key: string, keepLoggedIn: boolean, teams?: boolean) => {
    console.log(`INFO: Trying to confirm login ${email} ${key}`);
    const request = axios
    .get('/api/enter-account', {
      params: { email, key, keepLoggedIn, teams },
    }).then(() => {
      sessionStorage.setItem('qckEmail', email);
      console.log(`INFO: Login OK`);
    }).catch((err) => {
      console.log(`ERROR: Login error->${err}`);
    })
    return request;
  }) as TConfirmLoginCodeFunction,

  logout: (() => {
    console.log(`INFO: Logging out`);
    const request = axios
    .get('/api/log-out')
    .then(() => {
      sessionStorage.removeItem('qckEmail');
      console.log(`INFO: Logout ok`);
    });
    return request;
  }) as TLogoutFunction,
  getCurrentUser: (async () => {

    const userFromSessionStorage = sessionStorage.getItem('qckEmail');
    if(userFromSessionStorage){
      return userFromSessionStorage;
    }

    try{
      const result = (await axios.get('/api/get-account-name')).data;
      if(result){
        const email = result.message;
        sessionStorage.setItem('qckEmail', email);
        console.log("INFO: Call to AuthService - getCurrentUser ->", email);
        return email;
      }
    }catch(auth_error){ 
      console.log(`ERROR: ${auth_error}`);
    }
    
    console.log("INFO: Call to AuthService - getCurrentUser -> null");
    return null;
  }) as TGetCurrentUserFunction
}
