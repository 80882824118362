import * as React from 'react'
import style from './UserMeeting.module.css';
import {useUserMeeting} from '../../../contexts/UserMeetingContext';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import Dots from '../../../components/Dots';
import parseNumber from '../../../functions/parseNumber';
import Results from '../../../components/Meeting/Voting/Results/Results';
import ResultsPopup from '../../../components/Meeting/Voting/Results/ResultsPopup';
import Evaluation from '../../../components/Meeting/Evaluation';
import CustomMarkdownRenderer from '../../../components/CustomMarkdownRenderer';
import gfm from 'remark-gfm';
import {useMeeting} from '../../../contexts/MeetingContext';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import GivePopup from '../../../components/Meeting/Voting/Host/GivePopup';
import {ROLE_PERMISSION_MAP} from '../../../constants';
import Editor from '../../../components/Meeting/Started/Editor';
import randomColor from '../../../functions/randomColor';
import sanitizeInvitees from '../../../functions/SanitizeInvitees';
import FullScreenLoadingOverlay from '../../../components/FullScreenLoadingOverlay';
import useChangeBodyScrollStateOnShow from '../../../hooks/useChangeBodyScrollStateOnTrue';

export default function UserMeeting(props: {
  meeting: Meeting
}) {

  const [ isInitialized, setIsInitialized ] = React.useState<boolean>(false);
  
  const [ userRole, setUserRole ] = React.useState<Role>('User');

  const [ workingQuestions, setWorkingQuestions ] = React.useState<WorkingQuestion[]>(props.meeting.workingQuestions);
  const [ currentWorkingQuestionIndex, setCurrentWorkingQuestionIndex ] = React.useState<number>(props.meeting.currentWorkingQuestionIndex);
  const [ invitees, setInvitees ] = React.useState<Invitee[]>(props.meeting.invitees);
  const [ minutes, setMinutes ] = React.useState<Minute[]>(props.meeting.workingQuestionMinutes);
  const [ feedback, setFeedback ] = React.useState<Feedback>(props.meeting.feedback);
  const [ isMinuteContributionAllowed, setIsMinuteContributionAllowed] = React.useState<boolean>(props.meeting.isMinuteContributionAllowed || false);

  const [ currentWorkingQuestionDuration, setCurrentWorkingQuestionDuration ] = React.useState<number>(0);
  const [ currentWorkingQuestionDurationDisplay, setCurrentWorkingQuestionDurationDisplay ] = React.useState<string>('--:--');

  const [ dateTimeEnded, setDateTimeEnded ] = React.useState<number | undefined>(props.meeting.dateTimeEnded);
  const [ dateTimeStarted, setDateTimeStarted ] = React.useState<number | undefined>(props.meeting.dateTimeStarted);

  const [ showVotePopup, setShowVotePopup ] = React.useState<boolean>(false);
  const [ showResultsPopup, setShowResultsPopup ] = React.useState<boolean>(false);

  const [ loading, setLoading ] = React.useState<boolean>(false);

  const [ minuteChanges, setMinuteChanges ] = React.useState<MinuteChanges>({ newMinutes: [] });

  const { type } = props.meeting;

  const { id } = useParams();

  const incrementDurationIntervalId = React.useRef<number>();

  const popupBackgroundRef = React.useRef<HTMLDivElement>(null);

  const userEmail = sessionStorage.getItem('qckEmail') || '';

  const secondsToMMSS = (s: number): string => {
    const minutes = Math.floor(s / 60);
    const seconds = s - (minutes * 60);
    return `${parseNumber(minutes)}:${parseNumber(seconds)}`
  }

  const formatStart = (ms: number) => {
    const toDate = new Date(ms);
    return `${parseNumber(toDate.getHours())}:${parseNumber(toDate.getMinutes())}`;
  }

  const handleShowVotePrompt = () => {
    setShowVotePopup(true);
    setShowResultsPopup(false);
  }

  const handleShowResultsPrompt = () => {
    setShowVotePopup(false);
    setShowResultsPopup(true);
  }

  //useChangeBodyScrollStateOnShow(showVotePopup);
  //useChangeBodyScrollStateOnShow(showResultsPopup);


  const incrementCurrentWorkingQuestionDuration = () => {
    setCurrentWorkingQuestionDuration(curr => {
      const newDuration = curr + 1;
      setCurrentWorkingQuestionDurationDisplay(secondsToMMSS(newDuration));
      return newDuration;
    })
  }
  
  React.useEffect(() => {
    if(!userEmail || userEmail.length < 5) return;

    axios
    .post('/api/meeting-invitees', { changes: [ { type: 'new', props: { invitee: { 
      email: userEmail,
      invitationStatus: 'accepted',
      color: randomColor(),
      role: 'User'
    } as Invitee }}] as InviteeChange[], id });


  }, [] )

  React.useEffect(() => {
    window.clearInterval(incrementDurationIntervalId.current);
    incrementDurationIntervalId.current = window.setInterval(incrementCurrentWorkingQuestionDuration, 1000);
    return;
  }, [currentWorkingQuestionIndex]);

  const addMinuteChange = (index: number, text: string) => {
    setMinuteChanges((curr) => {
      const _newMinuteChanges = [ ...curr.newMinutes ];
      _newMinuteChanges[index] = text;
      return { ...curr, newMinutes: _newMinuteChanges };
    })
  }

  const requestForOwnership = (index: number, state: boolean) => {
    setMinuteChanges((curr) => {
      const _new = { ...curr };
      _new.takeMinuteOwnershipIndex = index;
      return _new;
    })
    setMinutes((curr) => curr.map((minute, _index) => {
      if(_index === index){
        if(!minute.owner){
          return { ...minute, owner: 
            state ? userEmail: undefined
          };
        }
      }
      return minute;
    }))
  }

  const handleMinuteWrite = (markdown: string) => {
    //TODO change minutes 
    setMinutes(curr => curr.map((minute, index) => {
      if(index === currentWorkingQuestionIndex){
        return { ...minute, text: markdown };
      }
      return minute;
    }))

    addMinuteChange(currentWorkingQuestionIndex, markdown);
  }

  const isRequestActive = React.useRef(false);

  const sync = () => {

    if(isRequestActive.current) return;

    setMinuteChanges(curr => {
      axios
      .post('/api/meeting-minute', { id, changes: curr })
      .then((result) => {
        const meeting = result.data as Meeting;
        setWorkingQuestions(meeting.workingQuestions);
        setInvitees(meeting.invitees);
        setMinuteChanges(curr => {
          if(!curr.newMinutes || curr.newMinutes.length <= 0){
            setMinutes(meeting.workingQuestionMinutes);
          }
          return curr;
        })
        setFeedback(meeting.feedback);
        setCurrentWorkingQuestionIndex((curr) => {
        if(curr != meeting.currentWorkingQuestionIndex){
          document.querySelector(`#WorkingQuestionIndex-${meeting.currentWorkingQuestionIndex}`)?.scrollIntoView({behavior: "smooth",block: "center"});
          curr = currentWorkingQuestionIndex
          }
          return meeting.currentWorkingQuestionIndex
        });
        setDateTimeEnded(meeting.dateTimeEnded);
        setDateTimeStarted(meeting.dateTimeStarted);
        setIsMinuteContributionAllowed(meeting.isMinuteContributionAllowed || false);
        const myEmail = sessionStorage.getItem('qckEmail') || '';
        const myRole = meeting.invitees.find((invitee) => invitee.email === myEmail)?.role || 'User';
        setUserRole(myRole);

        isRequestActive.current = false;
      })

      isRequestActive.current = true;
      return { newMinutes: [] };
    })

  }

  React.useEffect(() => {

    const interval = window.setInterval(sync, 1000);

    return () => window.clearInterval(interval);
  }, []);

  const submit = (answers: Answer[], opinions: Opinion[]) => {
     setLoading(true);
     axios
     .patch('/api/meeting-feedback', { answers, opinions: opinions, id })
     .then(() =>{
       setLoading(false);
       window.location.reload()
     });
    
  }


  //PATCH SANITIZE INVITEES
  React.useEffect(() => {
    const [ sanitizedInvitees, removedCount ] = sanitizeInvitees(invitees);
    if(removedCount){
      setInvitees(sanitizedInvitees);
    }
  }, [invitees]);

  return (
    <>
      <FullScreenLoadingOverlay show={loading} />
      <div 
        className={style.overlay}
        style={{
          display: showVotePopup || showResultsPopup ? 'grid' : 'none'
        }}
      >
        {showVotePopup && 
          <GivePopup 
            submit={submit}
            team_name_or_working_question={workingQuestions[currentWorkingQuestionIndex].text}
            type={type}
          />
        }
        {showResultsPopup && <ResultsPopup 
          type={type}
          feedback={feedback[currentWorkingQuestionIndex]}
          continueAction={() => setShowResultsPopup(false)}
          inviteesList={invitees}
          workingQuestionOrTeamName={workingQuestions[currentWorkingQuestionIndex].text}
          id={id}
        />
        }
      </div>
      <div className={`${style.mainContent} ${style.noScroll}`}>
        {isInitialized ?
          <h1 className={style.loading}>Loading<Dots /></h1>
        :
          dateTimeStarted && !dateTimeEnded ? 
          <>
            <ul
              className={style.workingQuestions}
            >
              {
                workingQuestions.length ? workingQuestions.map((wq, index) => {
                  if(wq.isBacklogged) return;
                 return (
                  <li
                    key={index}
                    id={`WorkingQuestionIndex-${index}`}
                    className={`${style.workingQuestionContainer} ${index === currentWorkingQuestionIndex ? style.show : style.hide}`}
                  >
                    <div
                      className={style.displayButton}
                    >
                      <div></div>
                      <span className={style.workingQuestionText}>
                        {wq.text}
                      </span>
                      <div className={style.startDurationWrapper}>
                        <div className={style.startDurationContainer}>
                          <span>Start: {wq.startDateTime ? formatStart(wq.startDateTime) : '--:--'}</span>
                          <span>Duration: {index === currentWorkingQuestionIndex ? currentWorkingQuestionDurationDisplay : wq.duration ? secondsToMMSS(wq.duration) : '--:--'}</span>
                        </div>
                      </div>
                    </div>
                    <section
                      className={style.dropdown}
                    >
                      {
                        //Has permissions
                        (
                          ROLE_PERMISSION_MAP[userRole].includes('minute.contribute.always') ||
                          (ROLE_PERMISSION_MAP[userRole].includes('minute.contribute.onallow') && isMinuteContributionAllowed)
                        )
                        //Is owner of wq
                        && (
                          !minutes[currentWorkingQuestionIndex].owner 
                          || minutes[currentWorkingQuestionIndex].owner === userEmail
                        )
                          ? 
                        <Editor
                          minute={minutes[index]}
                          isCurrent={index === currentWorkingQuestionIndex}
                          onEditorChange={handleMinuteWrite}
                          //unused
                          hasVotingEnded
                        />
                      :
                        <CustomMarkdownRenderer className={style.minutesField} remarkPlugins={[gfm]}>
                          { minutes[currentWorkingQuestionIndex].text || 'Once the host starts taking notes they will appear here.' }
                        </CustomMarkdownRenderer>
                      }
                      <div className={style.voteButtonWrapper}>
                        <Button 
                          style={{
                            fontSize: '2rem',
                            padding: '0 2rem'
                          }}
                          disabled={!feedback[currentWorkingQuestionIndex] || !feedback[currentWorkingQuestionIndex].votingStartedDateTime}
                          onClick={
                            feedback[currentWorkingQuestionIndex] && feedback[currentWorkingQuestionIndex].answers[userEmail] 
                              ? handleShowResultsPrompt
                              : handleShowVotePrompt
                          }
                        >
                          {
                            feedback[currentWorkingQuestionIndex] && feedback[currentWorkingQuestionIndex].answers[userEmail] 
                              ? 'View results'
                              : 'Give Feedback'
                          }
                        </Button>
                      </div>
                    </section>
                  </li>
                )})
              :
                <></>
              }
            </ul>
          </>
          :
          dateTimeEnded 
          ? <>
              <Evaluation 
                dateTimeEnded={dateTimeEnded}
                dateTimeStarted={dateTimeStarted}
                workingQuestions={workingQuestions}
                minutes={minutes}
                feedback={feedback}
                inviteesList={invitees}
                type={type}
              />
            </>
          : <div>
              <p
                className={style.hasntStarted}
              >
                Meeting will start soon<Dots />
              </p>
              {ROLE_PERMISSION_MAP[userRole].includes('agenda.new') && 
                <>
                  <p className={style.hasntStarted}>In the meantime you can contribute to the agenda by clicking <a 
                    style={{textDecoration: 'underline' }} 
                    href={`/p/${id}/contribute`}
                  >here.</a></p>
                </>}
            </div>
          }
      </div>
    </>
  )
}
