import React, {Ref} from 'react';
import style from './ResultsPrint.module.css';
import { global } from '../../../../types/global';
import { TYPE_QUESTIONS_MAP, MAX_QUESTION_ANSWER } from '../../../../constants';
import Slider from '../Results/Slider';
import { useTranslation } from 'react-i18next';
import gfm from 'remark-gfm';
import Circle from '../Results/Circle';
import CustomMarkdownRenderer from '../../../CustomMarkdownRenderer';

export default function ResultsPrint(props: {
  type: MeetingType,
  feedback: IndexedFeedback,
  emailColorMap: Record<Email, string>,
  contentRef: Ref<HTMLDivElement>
  minutes?: string,
  workingQuestionOrTeamName: string | null
}) {

  const { t } = useTranslation();

  const { 
    type,
    feedback,
    emailColorMap,
    contentRef,
    minutes,
    workingQuestionOrTeamName
  } = props;

  return (
    <div className={style.wrapper}>
      <div className={`${style.content} to-print`} ref={contentRef}>
        <h1 className={style.workingQuestion}>{workingQuestionOrTeamName}</h1>
        { minutes !== undefined && 
        <>
          <h2 className={style.notesHeading}>{t('Notes')}</h2>
          <CustomMarkdownRenderer remarkPlugins={[gfm]} className={style.notes}>
            {minutes}
          </CustomMarkdownRenderer>
        </>
        }
        {TYPE_QUESTIONS_MAP[type].map((feedbackQuestion, index) => {
          return (
            <div key={index} className={style.feedbackQuestion}>
              <p className={style.questionText}>{feedbackQuestion}</p>
              <Slider feedback={feedback} index={index} emailColorMap={emailColorMap}/>
              <div className={style.details} >
                <h1 className={style.detailsHeading}>
                  <span className={style.headingText}>
                    {t('Feedback')}
                  </span>
                  <span className={style.answerCount}>
                    {`${((): number => {
                      let count = 0;
                      for(const email in feedback.opinions){
                        if(feedback.opinions[email][index]){
                          count ++;
                        }
                      }
                      return count;
                    })()} answers`}
                  </span>
                </h1>
                {Object.keys(feedback.opinions).map((email, _index) => (
                  <>{ feedback.opinions[email][index] !== null ? 
                    <p className={style.opinion} key={index}>
                      <span>
                        <Circle letter={email.charAt(0)} yOffset={0} color={emailColorMap[email]} style={{position: "relative"}} />
                        {feedback.answers[email][index]}/{MAX_QUESTION_ANSWER}:
                      </span>
                      {feedback.opinions[email][index] === null ? t('Not applicable'):feedback.opinions[email][index] || t('No answer')}
                    </p>
                    : <></>
                  }</>
                ))}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
}
