import {RefObject, useEffect} from "react";
import html2pdf from "html2pdf.js";

export const useOverridePrintListener = (callback: () => void) => {
  useEffect(() => {
    window.addEventListener('keydown', overridePrintListener(callback));

    return () => window.removeEventListener('keydown', overridePrintListener(callback));
  }, []);
}

export const overridePrintListener = (callback: () => void) => {
  return (event: KeyboardEvent) => {
    if(!((event.ctrlKey || event.metaKey) && event.key === 'p')) return;
    
    event.preventDefault();
    callback();
  }
}

export const download = (content: HTMLDivElement | null, title?: string) => {
  if(!content) return;

  const options = {
    margin: .5, // Inches
    filename: title || 'react-document.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
  };

  html2pdf()
    .set(options)
    .from(content)
    .toPdf()
    .get('pdf')
    .save();
}

export const print = (content: HTMLDivElement | null) => {

  if(!content) return;

  const options = {
    margin: .5, // Inches
    filename: 'react-document.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
  };

  html2pdf()
    .set(options)
    .from(content)
    .toPdf()
    .get('pdf')
    .then((pdf: any) => {
      pdf.autoPrint();
      window.open(pdf.output('bloburl'));
    });

}

