import * as React from 'react';
import style from './Results.module.css';
import { TYPE_QUESTIONS_MAP, MAX_QUESTION_ANSWER } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import EvaluationGraph from '../../EvaluationGraph';
import ResultsPrint from '../ResultsPrint/ResultsPrint';
import Slider from './Slider';

export default function Results(props:{
  type: MeetingType,
  feedback: IndexedFeedback
  emailColorMap: Record<Email, string>
}) {

  const { type, feedback } = props;

  const { t } = useTranslation();

  const [openedDetailFields, setOpenedDetailFields] = React.useState<number[]>([]);
  const [listKey, setListKey ] = React.useState<number>(0);

  const rerenderList = () => setListKey(curr => curr + 1);


  const Circle = (props: {
    letter: string
    color: string
    yOffset: number
    style?: any
  }) => {
    return (
      <span 
        style={{
          backgroundColor: props.color,
          transform: `translateY(${-props.yOffset * 5}px)`,
          ...props.style
        }} 
        className={style.circle}
      >
        {props.letter}
      </span>
    )
  }


  //Calcs to determine which votes are repeating and need to be grouped
  //Returns a list of objects as {answerValue: number, letters: []}

  const toggleDetailsField = (feedbackQuestionIndex: number) => {
    setOpenedDetailFields(curr => {
      if(curr.includes(feedbackQuestionIndex)){
        return curr.filter((el) => el != feedbackQuestionIndex);
      }
      return [...curr, feedbackQuestionIndex];
    })
  }

  React.useEffect(() => {

    window.addEventListener('resize', rerenderList);

    rerenderList();

    return () => {
      window.removeEventListener('resize', rerenderList);
    }
  }, []);

  return (
    <>
       <ul className={`${style.feedbackQuestionList} no-print`} key={listKey}>
        {/*question*/}
        {/*slider*/}
        {/*details*/}
        {/*in details -> list of (L) n/N Name: Answer */}
        {TYPE_QUESTIONS_MAP[type].map((feedbackQuestion, index) => {
          return (
            <li key={index} className={style.feedbackQuestion}>
              <p className={style.questionText}>{feedbackQuestion}</p>
              <Slider feedback={props.feedback} index={index} emailColorMap={props.emailColorMap}/>
              <div className={style.details} onClick={() => toggleDetailsField(index)}>
                <h1 className={style.detailsHeading}>
                  <span className={style.headingText}>
                    {openedDetailFields.includes(index) ? 
                      t('Hide Details') : t('Show Details')
                    }
                  </span>
                  <span className={style.answerCount}>
                    {`${((): number => {
                      let count = 0;
                      for(const email in feedback.opinions){
                        if(feedback.opinions[email][index]){
                          count ++;
                        }
                      }
                      return count;
                    })()} answers`}
                  </span>
                </h1>
                {openedDetailFields.includes(index) && Object.keys(feedback.opinions).map((email, _index) => (
                  <> { feedback.opinions[email][index] !== null ? 
                  <p className={style.opinion} key={index}>
                    <span>
                      <Circle letter={email.charAt(0)} yOffset={0} color={props.emailColorMap[email]} style={{position: "relative"}} />
                      {feedback.answers[email][index]}/{MAX_QUESTION_ANSWER}:
                    </span>
                    {feedback.opinions[email][index] === null ? t('Not applicable'):feedback.opinions[email][index] || t('No answer')}
                  </p>
                  : <></>
                  }</>
                ))}
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}
