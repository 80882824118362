import * as React from 'react';
import style from './GatherPopup.module.css';
import {useParams} from 'react-router-dom';
import {useMeeting} from '../../../../contexts/MeetingContext';
import axios from 'axios';
import {Button} from '@mui/material';
import { clearFeedback } from '../../../../functions/debug';

export default function GatherPopup(props:{
  duration: number
  setShouldShowVotePopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldShowGatherPopup?: React.Dispatch<React.SetStateAction<boolean>>;
  invitees: Invitee[]
  currentWorkingQuestionIndex: number
  endVoting: (answers: Answers) => void
  ownerEmail: string
  feedback: IndexedFeedback
}) {
  const { invitees, currentWorkingQuestionIndex, endVoting, ownerEmail} = props;
  const [ answers, setAnswers ] = React.useState<Answers>(
    props.feedback ? props.feedback.answers || {} : {}
  );
  const [ hasHostVoted, setHasHostVoted ] = React.useState<boolean>(false);
  const { id } = useParams();

  const handleSubmit = () => {
    endVoting(answers);
    window.location.hash = '#results';
  }

  const showVotePopupForHost = () => {
    props.setShouldShowVotePopup(true);
    props.setShouldShowGatherPopup && props.setShouldShowGatherPopup(false);
  }

  React.useEffect(() => {
    setHasHostVoted(!!(answers && answers[ownerEmail] && answers[ownerEmail].length > 0));
  }, [answers])

  return (
    <div
      className={style.container} 
    >
      <h1
        className={style.heading}
      >
        Gathering feedback
      </h1>
      <h2
        className={style.heading2}
      >
        Here you can see everyone in your Qck, as well as their voting status for the current working question
      </h2>
      <ul
        className={style.inviteesList}
      >
        {invitees.map((invitee, index) => {
          const { email, color } = invitee;
          const isVoteSubmitted = 
            answers
            && answers[email]
            && answers[email].length > 0;
          return (
            <li
              className={style.invitee}
              key={index}
            >
              <span
                className={style.inviteeId}
              >
                <div className={style.circle} style={{ backgroundColor: color }}>{email.charAt(0)}</div>
                <span className={style.email}>{email}</span>
              </span>
              <span className={`${style.feedbackStatus} ${isVoteSubmitted ? style.submited : ''}`}>
                {isVoteSubmitted
                ? 'submited'
                : 'awaiting'}
              </span>
            </li>
          )
        })}
      </ul>
      <ul
        className={style.buttonsContainer}
      >
        <li>
          <Button
            variant='contained'
            style={{
              width: 'min-content',
              textWrap: 'nowrap',
              margin: '1rem auto 0 auto', 
              fontSize: '1.2rem'
            }}
            onClick={handleSubmit}
            className={style.actionButton}
            disabled={!hasHostVoted}
          >Results</Button>
        </li>
        <li>
          <Button
            variant='contained'
            style={{
              width: 'min-content',
              textWrap: 'nowrap',
              margin: '1rem auto 0 auto',
              fontSize: '1.2rem'
            }}
            className={style.actionButton}
            onClick={() => showVotePopupForHost()}
            disabled={hasHostVoted}
          >
            Give feedback
          </Button>
        </li>
      </ul>
    </div>
  )
}
