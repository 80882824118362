import validateEmail from "./EmailValidator";
/**
  * @param Invitee[]
  * Takes an array of invitees and removes the invitees with invalid emails
  * @returns 
  * tuple containing invitee[] and how many were removed
  */
export default function sanitizeInvitees(invitees: Invitee[]): [Invitee[], number]{
  
  let count = 0;
  const sanitizedInvitees: Invitee[] = [];

  invitees.forEach( invitee => {
    if(validateEmail(invitee.email)){
      sanitizedInvitees.push(invitee);
    }else{
      count ++;
    }
  })
  
  return [ sanitizedInvitees, count ];
}
