import UserAgreementLink from '../components/UserAgreementLink';
import Navbar from '../Navbar';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import * as React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../hooks/useAuth';
import '../styles/enterAccount.css';
import { v4 as uuid } from 'uuid';

export default function EnterAccount() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const { user, loading, confirm_login_code } = React.useContext(AuthContext)!;

  const { emailParam } = useParams();
  const { keyParam } = useParams();
  const inTeams = !!(new URLSearchParams(window.location.search).get('inTeams'));
  const keepLoggedIn = localStorage.getItem('keepLoggedIn') === 'true';

  const sendKeyAndEmail = () => {
    confirm_login_code(emailParam || '', keyParam || '', keepLoggedIn, inTeams)
  };

  useEffect(sendKeyAndEmail, [emailParam, keyParam]);

  return (
    <>
      <Navbar />
      <div style={{ textAlign: 'center' }}>
        <>
          <h1>Waiting...</h1>
        </>
        <UserAgreementLink />
      </div>
    </>
  );
}
