import React, {useEffect, useRef, useState} from 'react';
import style from './Vote.module.css';
import { useTranslation } from 'react-i18next';
import Dots from '../../../components/Dots';
import axios from 'axios';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import GivePopup from '../../../components/Meeting/Voting/Host/GivePopup';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import FullScreenLoadingOverlay from '../../../components/FullScreenLoadingOverlay';

export default function Vote(props: {
  meeting: QuickMeeting
  updateMeetingState: React.Dispatch<React.SetStateAction<QuickMeeting | undefined>>
  isLoggedInUserOwner: boolean
}){

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const QCK_VER = process.env.REACT_APP_QCK_VER;
  
  const userEmail = sessionStorage.getItem('qckEmail');
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [ joined, setJoined ] = useState<boolean>(false);

  const [ hasUserAlreadyVoted, setHasUserAlreadyVoted ] = useState<boolean>(
    QCK_VER === '2'
      ? false:
    QCK_VER === '1'
      ? searchParams.get("voted") === 'yes'
      : false
  );

  const timeBetweenRefreshes = 4 * 1000;
  const [ shouldEnableRefreshButton, setShouldEnableRefreshButton ] = useState<boolean>(false);

  const [ shouldShowLoadingOverlay, setShouldShowLoadingOverlay ] = useState<boolean>(false);

  const submit = (answers: Answer[], opinions: Opinion[], name?: string) => {
    console.log(`answers: ${answers}\nopinions: ${opinions}\nname:${name}\n`);

    if(QCK_VER === '1' && !name){
      return false;
    }

    axios.put('/api/quick-vote', { id, opinions, answers, email: name })
    .then((response) => {
      setShouldShowLoadingOverlay(false);
      if(QCK_VER === '1'){
        window.location.href = window.location.href + '?voted=yes';
      }else if(QCK_VER === '2'){
        window.location.reload();
      }
    })
    .catch((error) => {
      console.log(error);
    })
    setShouldShowLoadingOverlay(true);
    return true;
  }

  const navigateToResults = () => window.location.href = `/qck/${id}/results`; 

  const join = () => {
    if(QCK_VER === '2'){
      if(joined){ return; }
      
      if(props.meeting.invitees.find((invitee) => invitee.email === userEmail)){
        setJoined(true);
        return; 
      }

      axios
      .put('/api/quick-join', { id })
      .then((response) => {
        setJoined(true);
      })
      .catch((error) => {
        console.log(error);
        window.location.reload();
      })
    }else if(QCK_VER === '1'){
      setJoined(true);
    }
  }

  const refreshButtonTimeoutId = useRef<number>(-1);

  const enableRefreshButton = () => {

    console.log(`INFO: enabling refresh button`);
    setShouldEnableRefreshButton(true);
  }

  const refresh = () => {
    if(!shouldEnableRefreshButton){ return; }

    setShouldEnableRefreshButton(false);
    refreshButtonTimeoutId.current = window.setTimeout(enableRefreshButton, timeBetweenRefreshes);

    axios
    .get('/api/quick', { params: { id } })
    .then((response) => {
      const meeting: QuickMeeting = response.data;
      setShouldShowLoadingOverlay(false);
      if(!meeting){
        console.log(`ERROR: Couldn\'t fetch meeting. `);
        return;
      }

      if(meeting.areResultsPublic){
        console.log(`INFO: areResultsPublic changed -> updating local meeting state; areResultsPublic: ${meeting.areResultsPublic}`);
        props.updateMeetingState(meeting);
      }
    })
    .catch((error) => {
      console.log(`ERROR: ${error}`);
    });

    setShouldShowLoadingOverlay(true);
  }

  useEffect(() => {
    join();
    
    if(QCK_VER === '2'){
      //check has user already voted
      setHasUserAlreadyVoted(Object.keys(props.meeting.feedback.answers).includes(userEmail || ""));
    }
  }, []);

  useEffect(() => {
    if(!hasUserAlreadyVoted){ return; }

    console.log(`INFO: enabling refresh button in ${timeBetweenRefreshes}ms`);
    refreshButtonTimeoutId.current = window.setTimeout(enableRefreshButton, timeBetweenRefreshes);

    return () => window.clearTimeout(refreshButtonTimeoutId.current);
  }, [hasUserAlreadyVoted]);

  useEffect(() => {
    //console.log(`INFO: setShouldEnableRefreshButton state changed: ${shouldEnableRefreshButton}`);
    return;
  }, [shouldEnableRefreshButton]);

  return (
    <>
      <FullScreenLoadingOverlay show={shouldShowLoadingOverlay} />
      { joined ? 
      <>
        {!hasUserAlreadyVoted ? 
          <GivePopup type={props.meeting.type} submit={submit} team_name_or_working_question={props.meeting.workingQuestion.text || undefined} standalone showNameInput={ QCK_VER == '1' }/>
          :
          <div className={style.alreadyVoted}>
            <h1>{t('You have already voted!')}</h1>
            { props.meeting.areResultsPublic || props.isLoggedInUserOwner ? 
              <div className={style.resultsContainer}>
                <h2>{t('Click on the button below to see the results')}</h2>
                <Button
                  onClick={navigateToResults}
                >
                  {t('Results')}
                </Button>
              </div>
              :
              <div className={style.resultsContainer}>
                <h2>{t('You can see the results after the host has made them public')}</h2>
                <Button
                  disabled={!shouldEnableRefreshButton}
                  onClick={refresh}
                >
                  {t('Refresh')}
                </Button>
              </div>
            }
          </div>
        }
      </>
      : 
        <h1 className={style.joining}> {t('Joining')}<Dots fontSize='3rem'/></h1>
      }
    </>
  );
}
